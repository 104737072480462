import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"

export const StepTwo = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                {/* <span className="formCount">1.1</span> */}
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/4kg6r8qfcmv62zschqerp/9-The-people-you-are-going-to-help.mp4?rlkey=nxd9onulz8jk79y6nw8m48vqj&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="form__wrapper">
                    {!props.isFilled && (
                        <h3 className="form__title">The People</h3>
                    )}
                    {!props.isFilled ?
                        <p className="form__description">
                            Make a similar list, but describing the people you will target with your business.
                        </p>
                        :
                        <></>
                    }
                    <fieldset className="flex-col">
                        <FieldArray name="people2" {...props}>
                            {({ push }) => (
                                <div>
                                    {props.values.people2.length > 0 &&
                                        props.values.people2.map((people, index) => (
                                            <Field
                                                name={`people2.${index}.target`}
                                                placeholder="Personality trait / Adjective of your target"
                                                className="has-border-bottom"
                                                disabled={props.isFilled}
                                                key={index}
                                            />
                                        ))}
                                    {!props.isFilled && (
                                        <AddMore onClick={() => push({ people: '' })} />
                                    )}
                                </div>
                            )}
                        </FieldArray>
                    </fieldset>
                </div>
            </article>
        )
    }
}