import { Field, FieldArray } from "formik";
import ReactPlayer from "react-player";
import { AddMore } from "../../components/Buttons/AddMoreButton";

export const StepOne = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {

        return (
            <article className={handleArticleClassName()} style={{ flex: "0.3" }}>
                <div className="purpose__video">
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/kopqpb57vyote72b7y0x0/28-Market-Trends.mp4?rlkey=0woci39ktml7s9q5ayiafqo7n&raw=1"
                        playing
                        controls
                        className="react-player"
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    <span className="formCount">1.</span>
                    <div className="form__wrapper">
                        <h3 className="form__title">Competition</h3>
                        <p className="form__description">
                            Find your spot in the market. Bring about which corner of
                            the market you occupy by plotting your company in 2 or 4
                            spaces. Use this graph to plot where your company is today,
                            and where it is headed. This will give your team clarity
                            over how you intend to achieve your vision, and who you're
                            competing against.
                        </p>
                        <fieldset className="flex-col">
                            <FieldArray name="competition">
                                {({ insert, remove, push }) => (
                                    <div>
                                        {props.values.competition.length > 0 &&
                                            props.values.competition.map((competition, index) => (
                                                <div key={index} className="inline_competition">
                                                    <Field
                                                        name={`competition.${index}.competitionName`}
                                                        className="has-border-bottom"
                                                        placeholder="Enter competitor name"
                                                        value={undefined}
                                                    />
                                                    <Field
                                                        name={`competition.${index}.x`}
                                                        className="has-border-bottom"
                                                        placeholder="Value on the x axis"
                                                        type="number"
                                                        value={undefined}
                                                        style={{ display: "none" }}
                                                    />
                                                    <Field
                                                        name={`competition.${index}.y`}
                                                        className="has-border-bottom"
                                                        placeholder="Value on the y axis"
                                                        type="number"
                                                        value={undefined}
                                                        style={{ display: "none" }}
                                                    />
                                                </div>
                                            ))}
                                        <AddMore
                                            onClick={() => push({ competitionName: "", x: null, y: null })} />
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}