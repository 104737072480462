import { Field, FieldArray } from "formik";

export const StepFour = (props) => {
    const handleArticleClassName = () => {
        if(props.isFilled) return "form__container isFilled exit";
        if(props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className="form__wrapper">
                    <p className="form__description">
                        List companies that sound like that
                    </p>
                    <fieldset className="flex-col">
                        <FieldArray name="companiesThatSoundLikeThat" {...props}>
                            <div>
                                {props.initialValues.companiesThatSoundLikeThat.length > 0 &&
                                    props.initialValues.companiesThatSoundLikeThat.map(
                                        (companyThatSoundsLikeThat, index) => (
                                            <Field
                                                name={`companiesThatSoundLikeThat.${index}.companyThatSoundsLikeThat`}
                                                placeholder="Company"
                                                className="has-border-bottom"
                                                disabled={props.isFilled}
                                                key={index}
                                            />
                                        )
                                    )}
                            </div>
                        </FieldArray>
                    </fieldset>
                </div>
            </article>
        );
    }
};
