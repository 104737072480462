import { Field, FieldArray } from "formik";
import ReactPlayer from "react-player";

export const StepTwo = (props) => {
  const handleArticleClassName = () => {
    if(props.isFilled) return "form__container isFilled exit";
    if(props.enteringStep) return "form__container enter";
    else return "form__container";
}
  if (props.isActive) {
    return (
      <article className={handleArticleClassName()}>
        {/* <span className="formCount">1.1</span> */}
        {!props.secondRowIsVisible && (
          <div
            className={
              props.isFilled && props.isActive
                ? "purpose__video hidden"
                : "purpose__video visible"
            }
          >
            <ReactPlayer
              url="https://www.dropbox.com/scl/fi/8htsfqy4z70pz3cbeozlc/21-Look-and-Feel-Filter-5.mp4?rlkey=p5yay0fhfu5tn9w045xwypc2h&raw=1"
              playing={!props.isFilled}
              controls
              className="react-player"
              width="100%"
              height="100%"
            />
          </div>
        )}
        <div className="form__wrapper">
          {!props.isFilled && <h3 className="form__title">Look and Feel</h3>}
          {!props.isFilled ? (
            <p className="form__description">Pick the top 5 attributes</p>
          ) : (
            <p className="form__description">{"\n"}</p>
          )}
          <fieldset className="flex-col">
            <FieldArray name="fiveLookAndFeelAttributes" {...props}>
              <div>
                {props.initialValues.fiveLookAndFeelAttributes.length > 0 &&
                  props.initialValues.fiveLookAndFeelAttributes.map(
                    (lookAndFeelAttribute, index) => (
                      <Field
                        name={`fiveLookAndFeelAttributes.${index}.lookAndFeelAttribute`}
                        placeholder="Adjective"
                        className="has-border-bottom"
                        disabled={props.isFilled}
                        key={index}
                      />
                    )
                  )}
              </div>
            </FieldArray>
          </fieldset>
        </div>
      </article>
    );
  }
};
