import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { ProductSteps } from "../../../steps/purpose/product/ProductSteps"

export const PurposeProduct = () => {
    return (
        <div className="wrapper">
            <main className="product has-black-bg container">
                <ProductSteps />
            </main>
            <HeaderSidebar activeEl="Purpose" />
        </div>
    )
}