import { CustomChart } from "../../../components/Chart/Chart";
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar";
import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { StepOne } from "../../../steps/relationships/StepOne";
import { submitCompetitionForm } from "../../../services";
import { useSelector } from "react-redux";
import { Box } from "../../../components/Progress/Box";
import { StepperButtons } from "../../../components/Stepper/Buttons";

const initialValuesAttributes = {
  competitionAttributes: ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
  competition: [
    {
      competitionName: "",
      x: 0,
      y: 0,
    },
  ],
};

export const RelationshipsCompetition = () => {
  const [triggeredAnimation, setTriggeredAnimation] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const chartRef = useRef(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const id = useSelector(state => {
    return state.user.user.id;
  });

  const updatedLabels = (chart, values) => {
    if (values.competition[values.competition.length - 1].competitionName === "") {
      return;
    } else if (data.labels.includes(values.competition[values.competition.length - 1].competitionName)) {
      return;
    }
    else {
      data.labels.push(values.competition[values.competition.length - 1].competitionName)
    }
    data.datasets[0].data.push({ x: 0, y: 0 });
    data.datasets[0].backgroundColor.push("transparent");
    data.datasets[0].borderColor.push("white");
    chart.update();
  };

  const _handleSubmit = (values) => {
    setIsLoading(true);
    submitCompetitionForm(id, [{ attributes: values.competitionAttributes, labels: data.labels, positions: data.datasets[0].data }]).then(response => {
      const data = response.data;
      setIsLoading(false);
      navigate("/flow/relationships/trends");
    }).catch(err => {
      setIsLoading(false);
      console.log(err);
    })
  };

  const data = {
    labels: ["Your Company Now", "Your Aim"],
    datasets: [
      {
        backgroundColor: ["white", "#FCE816", "transparent"],
        borderColor: ["white", "transparent", "white"],
        data: [
          {
            x: 3,
            y: 0,
          },
          {
            x: 4,
            y: 5,
          },
        ],
      },
    ],
  };

  return (
    <div className="wrapper">
      <main className="relationships has-black-bg container">
        <section className="competition chart formsWrapper">
          <Formik
            initialValues={initialValuesAttributes}
            onSubmit={_handleSubmit}
          >
            {({ values }) => (
              <Form onBlur={() => updatedLabels(chartRef.current, values)}>
                <Box step={0} steps="1" />
                <div className="flex">
                  <StepOne values={values} isActive={activeStep === 0} />
                  <article className={triggeredAnimation ? "form__container enter six" : "form__container six"}>
                    <FieldArray name="competitionAttributes">
                      <div className="chart__container">
                        <fieldset className="chart__container-top">
                          <Field
                            className="has-border-bottom"
                            name={`competitionAttributes.${1}`}
                            id="competitionAttributes.topAttribute"
                            placeholder="Value 2"
                            value={undefined}
                          />
                        </fieldset>
                        <fieldset className="chart__container-center">
                          <Field
                            className="has-border-bottom"
                            name={`competitionAttributes.${0}`}
                            id="competitionAttributes.leftAttribute"
                            placeholder="Value 1"
                            value={undefined}
                          />
                          <CustomChart ref={chartRef} data={data} />
                          <Field
                            className="has-border-bottom"
                            id="competitionAttributes.rightAttribute"
                            name={`competitionAttributes.${2}`}
                            placeholder="Value 3"
                            value={undefined}
                          />
                        </fieldset>
                        <fieldset className="chart__container-bottom">
                          <Field
                            className="has-border-bottom"
                            name={`competitionAttributes.${3}`}
                            id="competitionAttributes.bottomAttribute"
                            placeholder="Value 4"
                            value={undefined}
                          />
                        </fieldset>
                      </div>
                    </FieldArray>
                  </article>
                </div>
                <StepperButtons
                  link="/flow/personality/attributes"
                  isLoading={isLoading}
                />
              </Form>
            )}
          </Formik>
        </section>
      </main>
      <HeaderSidebar activeEl="Relationships" />
    </div>
  );
};
