import { Form, Formik, Field } from "formik";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { login } from "../../../services";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { images } from "../../../assets/images";
import { Oval } from "react-loader-spinner";
import { loginReducer } from "../../../store/userSlice";

export const Login = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const initialValues = {
        email: '',
        password: '',
    }

    const handleSubmit = (values) => {
        setIsLoading(true);
        login(values.email, values.password).then(response => {
            setIsLoading(false);
            const data = response.data;
            dispatch(loginReducer(data, "user/login"))
            const params = new URLSearchParams(location.search);
            const redirectUrl = params.get('redirect') || '/';
            window.location.href = redirectUrl;
        }).catch(err => {
            setIsLoading(false);
            setErrorMessage(err.response.data.msg);
        })
    }

    const LoginSchema = Yup.object().shape({
        email: Yup.string().min(5, "Too Short!").max(100, "Too Long!").required("Required"),
        password: Yup.string().min(5, "Too Short!").max(100, "Too Long!").required("Required")
    });

    return (
        <main className="login has-black-bg has-full-height">
            <header className="login__header">
                <a href="https://skinsagency.com">
                    <img src={images.logo} alt="logo" />
                </a>
            </header>
            <div className="container">
                <article className="form__container">
                    <h1 className="form__title">Login</h1>
                    {errorMessage !== "" ? (
                        <p className="has-yellow-text">
                            {errorMessage}
                        </p>
                    ) : (
                        <></>
                    )}
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(values => handleSubmit(values))}
                        autoComplete="off"
                        validationSchema={LoginSchema}
                    >
                        {({ errors, touched }) => (
                            <Form method="POST" autoComplete="off">
                                <fieldset className="flex-col">
                                    <Field
                                        name="email"
                                        type="text"
                                        className="has-border-bottom"
                                        placeholder="Email"
                                        autoComplete="off" />
                                    {errors.email && touched.email ? (
                                        <p className="required has-red-text">{errors.email}</p>
                                    ) : null}
                                </fieldset>
                                <fieldset className="flex-col">
                                    <Field
                                        name="password"
                                        type="password"
                                        className="has-border-bottom"
                                        placeholder="Password"
                                        autoComplete="off" />
                                    {errors.password && touched.password ? (
                                        <div className="required has-red-text">{errors.password}</div>
                                    ) : null}
                                </fieldset>
                                <button className="btn btn-login" type="submit">
                                    {isLoading ? (
                                        <Oval
                                            height={30}
                                            width={50}
                                            color="black"
                                            secondaryColor="black"
                                        />
                                    ) : (
                                        <span className="text-black">Login</span>
                                    )}
                                </button>
                                <Link to="/register">Don't have an account? <span className="has-yellow-text">Click here to register.</span></Link>
                            </Form>
                        )}
                    </Formik>
                </article>
            </div>
        </main >
    )
}