import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"

export const StepOne = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/0fy2cnsw1hyfshijav42t/14-The-Features.mp4?rlkey=350nfqyoh97qcncs5dreugk6i&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    {!props.isFilled && (
                        <span className="formCount">4.</span>
                    )}
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">Product/Service</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                List your products/services
                            </p>
                            :
                            <></>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="productService1" {...props}>
                                {({ push }) => (
                                    <div>
                                        {props.values.productService1.length > 0 &&
                                            props.values.productService1.map((proud, index) => (
                                                <Field
                                                    name={`productService1.${index}.productService`}
                                                    placeholder="Write the product or service that you are providing"
                                                    className="has-border-bottom"
                                                    disabled={props.isFilled}
                                                    key={index}
                                                    as={TextareaAutosize}
                                                // value={undefined}
                                                />
                                            ))}
                                        {!props.isFilled && (
                                            <AddMore onClick={() => push({ productService: '' })} />
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}