import { useState } from "react"
import { useEffect } from "react";
import { positioning } from "../../services/resultsService";
import { Step } from "./Step";

export const ResultsPositioning = ({ brandName }) => {
    const [content, setContent] = useState({
        statement: "Positioning Statement",
        secondaryStatement: "Content is being loaded.",
        paragraph: "Content is being loaded"
    });
    
    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "04",
        section: "Positioning",
        company: companyName
    }

    useEffect(() => {
        positioning().then(response => {
            const { positioning, company} = response.data;
            if(positioning) {
                setContent(JSON.parse(positioning))
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(error => {
            console.log(error);
        })
    }, []);

    return (
        <Step header={header}>
            <h1 className="results__title">
                {content.statement}
            </h1>
            <h2 className="results__subtitle">
                {content.secondaryStatement}
            </h2>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}