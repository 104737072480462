import { useEffect, useState } from "react"
import { vision } from "../../services/resultsService";
import { Step } from "./Step";

export const ResultsVision = () => {
    const [content, setContent] = useState({
        statement: 'Vision Statement.',
        paragraph: 'Content is being loaded.'
    });

    const [companyName, setCompanyName] = useState('Your Company');

    const header = {
        index: "02",
        section: "Vision",
        company: companyName
    }

    useEffect(() => {
        vision().then(response => {
            const { vision, company } = response.data;
            if (vision) {
                setContent(JSON.parse(vision));
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <Step header={header}>
            <h1 className="results__title">
                {content.statement}
            </h1>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}