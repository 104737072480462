import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { PassionSteps } from "../../../steps/purpose/passion/PassionSteps"

export const PurposePassion = () => {
    return (
        <div className="wrapper">
            <main className="purpose has-black-bg container">
                <PassionSteps />
            </main>
            <HeaderSidebar activeEl="Purpose" />
        </div>
    )
}