import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { VisionSteps } from "../../../steps/vision/VisionSteps"

export const Vision = () => {
    return (
        <div className="wrapper">
            <main className="vision has-black-bg container">
                <VisionSteps />
            </main>
                <HeaderSidebar activeEl="Vision" />
        </div>
    )
}