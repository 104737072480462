import { Field, FieldArray } from "formik";
import { AddMore } from "../../../components/Buttons/AddMoreButton";
import ReactPlayer from "react-player";
import { useRef, useState } from "react";
import Countdown from "react-countdown";

export const StepOne = (props) => {
  const timerRef= useRef(null);
  const [dateNow, setDateNow] = useState(Date.now());

  const handleArticleClassName = () => {
    if(props.isFilled) return "form__container isFilled exit";
    if(props.enteringStep) return "form__container enter";
    else return "form__container";
}

  const [isDisabled, setIsDisabled] = useState(false);
  const [showTimeOut, setShowTimeout] = useState(false);

  const handlePlay = () => {
    console.log("playing");
  }

  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setIsDisabled(true);
      setShowTimeout(true);
    } else {
      if (seconds < 10) {
        return (
          <span className="has-yellow-text">
            0{minutes} : 0{seconds}
          </span>
        );
      } else {
        return (
          <span className="has-yellow-text">
            0{minutes} : {seconds}
          </span>
        );
      }
    }
  };

  if (props.isActive) {
    return (
      <article className={handleArticleClassName()}>
        {!props.secondRowIsVisible && (
          <div
            className={
              props.isFilled
                ? "flex centerAligned gap-1 hidden timerContainer"
                : "flex centerAligned gap-1 timerContainer"
            }
          >
          {!props.isFilled && (
            !showTimeOut ? (
            <Countdown
              ref={timerRef}
              date={dateNow + 5 * 60 * 1000}
              renderer={countdownRenderer}
            />
            ) : (
              <span className="has-red-text">
                 Timeout!
              </span>
            )
            )}
            <div
              className={
                props.isFilled && props.isActive
                  ? "purpose__video hidden"
                  : "purpose__video visible"
              }
            >
              <ReactPlayer
                url="https://www.dropbox.com/scl/fi/8hohbr8qgntn5lak9fqhn/20-Look-and-Feel-1.mp4?rlkey=c5t17ryq3bwj7hdgh758d7fry&raw=1"
                controls
                className="react-player"
                width="100%"
                height="100%"
                onPlay={handlePlay}
                value={undefined}
              />
            </div>
          </div>
        )}
        <section className="form__question">
          {!props.isFilled && <span className="formCount">2.</span>}
          <div className="form__wrapper">
            {!props.isFilled && <h3 className="form__title">Look and Feel</h3>}
            {!props.isFilled ? (
              <p className="form__description">
                Before we design your brand, we need to understand its overall
                personality and charisma. Just like people, we change how we
                dress over time, but people will still recognize us by our
                style. So, if your brand were a person, walking in the street,
                and you saw them from afar, what would they look like? Another
                analogy would be: if they were a bar, what would the interior
                look like? - Only use adjectives.
              </p>
            ) : (
              <p className="form__description">{"\n"}</p>
            )}
            <fieldset className="flex-col" disabled={isDisabled}>
              <FieldArray name="allLookAndFeelAttributes" {...props}>
                {({ push }) => (
                  <div>
                    {props.values.allLookAndFeelAttributes.length > 0 &&
                      props.values.allLookAndFeelAttributes.map(
                        (lookAndFeelAttribute, index) => (
                          <Field
                            name={`allLookAndFeelAttributes.${index}.lookAndFeelAttribute`}
                            placeholder="Adjective"
                            className="has-border-bottom"
                            disabled={isDisabled || props.isFilled}
                            key={index}
                          />
                        )
                      )}
                    {!props.isFilled && (
                      <AddMore
                        onClick={() => push({ lookAndFeelAttribute: "" })}
                      />
                    )}
                  </div>
                )}
              </FieldArray>
            </fieldset>
          </div>
        </section>
      </article>
    );
  }
};
