import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { LookSteps } from "../../../steps/personality/look/LookSteps"

export const PersonalityLook = () => {
    return (
        <div className="wrapper">
            <main className="personality has-black-bg container">
                <LookSteps />
            </main>
            <HeaderSidebar activeEl="Personality & Charisma" />
        </div>
    )
}