import ReactPlayer from "react-player";
import { Field } from "formik";
import { TextareaAutosize } from "@mui/material";

export const StepTwo = (props) => {
    const validateProblemStatement = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        }
        return error;
    }
    return (
        <article className={props.triggeredAnimation ? "form__container enter" : "form__container"}>
            <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                <ReactPlayer
                    url="https://www.dropbox.com/scl/fi/jj7hg18dbhg1w3fi8h5oe/2-Problem.mp4?rlkey=b7lq0sesa8xl59f6khi96ekiu&raw=1"
                    playing
                    controls
                    className='react-player'
                    width="100%"
                    height="100%"
                />
            </div>
            <section className="form__question">
                <span className="formCount">2.</span>
                <div className="form__wrapper">
                    <h3 className="form__title">What problem are you solving?</h3>
                    <p className="form__description">
                        e.g.: Founders today struggle to build a companies and remain true to their purpose while scaling their companies. They start their business with a great purpose, but on the way end up losing focus. That causes increased depression rates among founders, and ultimately less happy employees, and far less successful businesses.
                    </p>
                    <fieldset className="flex-col">
                        <Field
                            id="problemStatement"
                            name="problemStatement"
                            placeholder="Write problem statement here."
                            className="has-border-bottom"
                            // value={undefined}
                            as={TextareaAutosize}
                            validate={validateProblemStatement}
                        />
                        {props.errors.problemStatement && props.touched.problemStatement && (
                            <div className="required has-red-text">{props.errors.problemStatement}</div>
                        )}
                    </fieldset>
                </div>
            </section>
        </article>
    )
}