import ReactPlayer from "react-player";
import { Field } from "formik";

export const StepOne = (props) => {

    const validateCompanyName = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        }
        return error;
    }

    return (
        <article className={props.triggeredAnimation ? "form__container enter" : "form__container"}>
            <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible resizable"}>
                <ReactPlayer
                    url="https://www.dropbox.com/scl/fi/p9xoclckxbcn32wa6ygw5/1-Welcome.mp4?rlkey=gtccladqszb1n6gdi89t1qphp&raw=1"
                    playing
                    controls
                    className='react-player'
                    width="100%"
                    height="100%"
                />
            </div>
            <section className="form__question">
                <span className="formCount">1.</span>
                <div className="form__wrapper">
                    <h3 className="form__title">What is the name of your company?</h3>
                    <p className="form__description">
                    </p>
                    <fieldset className="flex-col">
                        <Field
                            id="companyName"
                            name="companyName"
                            placeholder="Enter your company name here."
                            className="has-border-bottom"
                            validate={validateCompanyName}
                        />
                        {props.errors.companyName && props.touched.companyName && (
                            <div className="required has-red-text">{props.errors.companyName}</div>
                        )}
                    </fieldset>
                </div>
            </section>
        </article>
    )

}