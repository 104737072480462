import { Link } from "react-router-dom";

export const HeaderSidebar = (props) => {
    const activeIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g transform="translate(-1781 -349)">
                <circle
                    cx="7.5"
                    cy="7.5"
                    r="7.5"
                    transform="translate(1781 349)"
                    fill="#fcfcfc"
                />
            </g>
        </svg>
    );

    const inActiveIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="7"
            viewBox="0 0 7 7"
        >
            <g transform="translate(-1816 -405)">
                <circle
                    cx="3.5"
                    cy="3.5"
                    r="3.5"
                    transform="translate(1816 405)"
                    fill="#c7c7ba"
                />
            </g>
        </svg>
    );

    return (
        <nav className="header__sidebar">
            <ul>
                <Link to="/flow/intro">
                    <li className={props.activeEl === "Intro" ? "active" : "inactive"}>
                        <span>
                            Intro
                        </span>
                        {props.activeEl === "Intro" ? activeIcon : inActiveIcon}
                    </li>
                </Link>
                <Link to="/flow/purpose">
                    <li className={props.activeEl === "Purpose" ? "active" : "inactive"}>
                        <span>
                            Purpose
                        </span>
                        {props.activeEl === "Purpose" ? activeIcon : inActiveIcon}
                    </li>
                </Link>
                <Link to="/flow/vision">
                    <li className={props.activeEl === "Vision" ? "active" : "inactive"}>
                        <span>Vision</span>
                        {props.activeEl === "Vision" ? activeIcon : inActiveIcon}
                    </li>
                </Link>
                <Link to="/flow/personality/introduction">
                    <li
                        className={
                            props.activeEl === "Personality & Charisma" ? "active" : "inactive"
                        }
                    >
                        <span>Personality & Charisma</span>
                        {props.activeEl === "Personality & Charisma" ? activeIcon : inActiveIcon}
                    </li>
                </Link>
                <Link to="/flow/relationships/competition">
                    <li className={props.activeEl === "Relationships" ? "active" : "inactive"}>
                        <span>Relationships</span>
                        {props.activeEl === "Relationships" ? activeIcon : inActiveIcon}
                    </li>
                </Link>
            </ul>
        </nav>
    )
} 