import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom"
import { getCompleted, getCompletedPayment, isAuthenticated } from "../../services";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logoutReducer } from "../../store/userSlice";


/* TODO in desperate need of refactoring */

export const ProtectedRoute = ({ children, shouldCheckPayment, shouldCheckCompleted }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [user, setUser] = useState(true);
    const [hasPaid, setHasPaid] = useState(null);
    const [hasCompleted, setHasCompleted] = useState(false);

    useEffect(() => {
        isAuthenticated().then(response => {
            const data = response.data;
            if (data === 1) {
                setUser(true)
            } else {
                setUser(false);
                dispatch(logoutReducer())
            }
        });

        getCompletedPayment().then(response => {
            const data = response.data;
            if (data === 1) {
                setHasPaid(true)
            } else {
                setHasPaid(false);
            }
        });

        getCompleted().then(response => {
            const data = response.data;
            if (data === 1) {
                setHasCompleted(true)
            } else {
                setHasCompleted(false);
            }
        });

    }, [navigate, dispatch]);

    if (!user) {
        return <Navigate to="/login" replace/>
    }

    if (shouldCheckCompleted && hasCompleted) {
        return <Navigate to="/results" replace />
    }

    if (shouldCheckPayment && hasPaid === false) {
        return <Navigate to="/payment-failed" replace />
    }

    if (shouldCheckPayment && hasPaid) return children;
    if (shouldCheckCompleted && !hasCompleted) return children;
}