import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { ToneSteps } from "../../../steps/personality/tone/ToneSteps"

export const PersonalityTone = () => {
    return (
        <div className="wrapper">
            <main className="personality has-black-bg container">
                <ToneSteps />
            </main>
            <HeaderSidebar activeEl="Personality & Charisma" />
        </div>
    )
}