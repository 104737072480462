import { useEffect, useState } from "react"
import { trends } from "../../services/resultsService";
import { Step } from "./Step";
import { v4 as uuidv4 } from 'uuid';

export const ResultsTrends = () => {
    const [content, setContent] = useState({
        trends: [
            {
                trend: 'Market Trend'
            },
            {
                trend: 'Market Trend'
            },
            {
                trend: 'Market Trend'
            }
        ],
        paragraph: 'Content is being loaded'
    })

    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "09",
        section: "Relationships - Market Trends",
        company: companyName
    }

    useEffect(() => {
        trends().then(response => {
            const { trends, company } = response.data;
            if (trends) {
                setContent(JSON.parse(trends))
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(error => {
            console.log(error);
        })
    }, [])

    return (
        <Step header={header} values>
            <ul className="results__list">
                {content.trends.map(el => (
                    <li className="value" key={uuidv4()}>{el.trend}</li>
                ))}
            </ul>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}