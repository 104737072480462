import axios from "axios";
import config from "../config";
const url = config.URL;

export const axiosInstance = axios.create({
    baseURL: url,
});

axiosInstance.interceptors.request.use(
    async config => {
        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers = {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Origin': "*"
            };
        }
        return config;
    },
    error => {
        Promise.reject(error);
    });


axiosInstance.interceptors.response.use((response) => {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    const status = error.response?.status;
    const errCode = error.code;
    if(status && status === 401) {
        if((window.location.pathname !== "/login") && window.location.pathname !== "/register") {
            const attemptedUrl = window.location.href;
            window.location.href = `/login?redirect=${encodeURIComponent(attemptedUrl)}`
        }
        return Promise.reject(error);
    }
    if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
        sessionStorage.setItem("token", "");
    } 
    if(errCode && errCode === "ERR_NETWORK") {
        return Promise.reject(error);
    }
    return Promise.reject(error);
})