import { axiosInstance as axios } from "./axiosInstance";

export const signUp = (name, surname, website, email, password) => {
    const config = {
        headers: {
            'accept': "application/json"
        }
    }
    const data = {
        name,
        surname,
        website,
        email,
        password,
    }
    return axios.post("/api/signup", data, config);
}

export const login = (email, password) => {
    const config = {
        headers: {
            'accept': "application/json"
        }
    }
    const data = {
        email,
        password
    }
    return axios.post("/api/login", data, config);
}

export const isAuthenticated = () => {
    return axios.get("/api/isAuthenticated");
}

export const submitIntroForm = (id, introForm) => {
    const data = {
        id,
        introForm
    }
    return axios.post("/api/contacts/submitIntroForm", data);
}

export const submitPassionForm = (id, passionForm) => {
    const data = {
        id,
        passionForm
    }
    return axios.post("/api/contacts/submitPassionForm", data);
}

export const submitPeopleForm = (id, peopleForm) => {
    const data = {
        id,
        peopleForm
    }
    return axios.post("/api/contacts/submitPeopleForm", data);
}

export const submitImpactForm = (id, impactForm) => {
    const data = {
        id,
        impactForm
    }
    return axios.post("/api/contacts/submitImpactForm", data);
}

export const submitProductForm = (id, productForm) => {
    const data = {
        id,
        productForm
    }
    return axios.post("/api/contacts/submitProductForm", data);
}

export const submitVisionForm = (id, visionForm) => {
    const data = {
        id,
        visionForm
    }
    return axios.post("/api/contacts/submitVisionForm", data);
}

export const submitValuesForm = (valuesForm) => {
    const data = {
        valuesForm
    }
    return axios.post("/api/contacts/submitValuesForm", data);
}

export const submitLookForm = (id, lookForm) => {
    const data = {
        id,
        lookForm
    }
    return axios.post("/api/contacts/submitLookForm", data);
}

export const submitToneForm = (id, toneForm) => {
    const data = {
        id,
        toneForm
    }
    return axios.post("/api/contacts/submitToneForm", data);
}

export const submitAttributesForm = (id, attributesForm) => {
    const data = {
        id,
        attributesForm
    }
    return axios.post("/api/contacts/submitAttributesForm", data);
}

export const submitCompetitionForm = (id, competitionForm) => {
    const data = {
        id,
        competitionForm
    }
    return axios.post("/api/contacts/submitCompetitionForm", data);
}

export const submitTrendsForm = (id, trendsForm) => {
    const data = {
        id,
        trendsForm
    }
    return axios.post("/api/contacts/submitTrendsForm", data);
};

export const getCompleted = () => {
    return axios.get(`/api/v2/contacts/completed`);
}

export const setCompleted = () => {
    return axios.post(`/api/v2/contacts/completed`);
}

export const getCompletedPayment = () => {
    return axios.get(`/api/hasCompletedPayment`);
}

export const completePayment = (hasCompletedPayment) => {
    const data = {
        hasCompletedPayment,
    }

    return axios.post(`/api/hasCompletedPayment`, data);
}

export const makePayment = () => {
    return axios.post(`/api/makePayment`);
}

export const getPurpose = () => {
    axios.get('/api/results/purpose');
}

export const getContact = id => {
    axios.get(`/api/contacts/${id}`)
}