import { Field, FieldArray, Form, Formik } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton";
import { Link, useNavigate } from "react-router-dom";
import { submitValuesForm } from "../../../services";
import ReactPlayer from "react-player";
import { useEffect } from "react";
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar";
import { TextareaAutosize } from "@mui/material";
import { useState } from "react";
import { getValuesForm } from "../../../services/autofillService";
import { Box } from "../../../components/Progress/Box";
import { StepperButtons } from "../../../components/Stepper/Buttons";

export const PersonalityValues = () => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        personalityValues: [
            {
                personalityValue: '',
            }
        ]
    })
    const [triggeredAnimation, setTriggeredAnimation] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const _handleSubmit = values => {
        setIsLoading(true);
        submitValuesForm(values).then(response => {
            setIsLoading(false);
            navigate("/flow/personality/look");
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }

    useEffect(() => {
        getValuesForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data);
            }
        }).catch(err => {
            console.log(err);
        })
    }, []);
    return (
        <div className="wrapper">
            <main className="personality has-black-bg container">
                <section className="passion formsWrapper">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        enableReinitialize
                    >
                        {({ values }) => (
                            <Form>
                                <Box step={0} steps="1" />
                                <div className="flex">
                                    <article className={triggeredAnimation ? "form__container enter" : "form__container"}>
                                        <div className="purpose__video">
                                            <ReactPlayer
                                                url="https://www.dropbox.com/scl/fi/spp6tonptltckp46kx2oz/19-Personality-and-Charisma.mp4?rlkey=r30z49ua6xlo0m3u0zzcd8tgw&raw=1"
                                                playing
                                                controls
                                                className='react-player'
                                                width="100%"
                                                height="100%"
                                            />
                                        </div>
                                        <section className="form__question">
                                            <span className="formCount">1.</span>
                                            <div className="form__wrapper">
                                                <h3 className="form__title">Values</h3>
                                                <p className="form__description">
                                                    What are the core values that you bring to the table that are not your product per-se, but are embedded in the way that you offer your product/service? This is where your core team's values come into play as well.
                                                </p>
                                                <fieldset className="flex-col">
                                                    <FieldArray name="personalityValues" render={arrayHelpers => (
                                                        <div>
                                                            {values.personalityValues.length > 0 &&
                                                                values.personalityValues.map((personalityValue, index) => (
                                                                    <Field
                                                                        name={`personalityValues.${index}.personalityValue`}
                                                                        placeholder="Value"
                                                                        className="has-border-bottom"
                                                                        key={index}
                                                                        as={TextareaAutosize}
                                                                    // value={undefined}
                                                                    />
                                                                ))}
                                                            <AddMore onClick={(() => arrayHelpers.push({ personalityValue: '' }))} />
                                                        </div>
                                                    )} />
                                                </fieldset>
                                            </div>
                                        </section>
                                    </article>
                                    <StepperButtons
                                        link="/flow/personality/introduction"
                                        isLoading={isLoading}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </main>
            <HeaderSidebar activeEl="Personality & Charisma" />
        </div>
    )
}