import { Link } from "react-router-dom"

export const ResultsSidebar = (props) => {
    return (
        <nav>
            <ul>
                <Link to="/results/purpose">
                    <li className={props.activeEl === "Purpose" ? "active" : ""}>
                        <span>
                            Purpose
                        </span>
                    </li>
                </Link>
                <Link to="/results/vision">
                    <li className={props.activeEl === "Vision" ? "active" : ""}>
                        <span>
                            Vision
                        </span>
                    </li>
                </Link>
                <Link to="/results/values">
                    <li className={props.activeEl === "Values" ? "active" : ""}>
                        <span>
                            Values
                        </span>
                    </li>
                </Link>
                <Link to="/results/positioning">
                    <li className={props.activeEl === "Positioning" ? "active" : ""}>
                        <span>
                            Positioning
                        </span></li>
                </Link>
                <Link to="/results/look">
                    <li className={props.activeEl === "Personality and Charisma - Look and Feel" ? "active" : ""}>
                        <span>
                            Look and Feel
                        </span>
                    </li>
                </Link>
                <Link to="/results/tone">
                    <li className={props.activeEl === "Personality and Charisma - Tone of Voice" ? "active" : ""}>
                        <span>
                            Tone of Voice
                        </span>
                    </li>
                </Link>
                <Link to="/results/attributes">
                    <li className={props.activeEl === "Personality and Charisma - Brand Attributes" ? "active" : ""}>
                        <span>
                            Brand Attributes
                        </span>
                    </li>
                </Link>
                <Link to="/results/competition">
                    <li className={props.activeEl === "Relationships - Competition" ? "active" : ""}>
                        <span>
                            Competition
                        </span>
                    </li>
                </Link>
                <Link to="/results/trends">
                    <li className={props.activeEl === "Relationships - Market Trends" ? "active" : ""}>
                        <span>
                            Market Trends
                        </span>
                    </li>
                </Link>
                <Link to="/results/content">
                    <li className={props.activeEl === "Content" ? "active" : ""}>
                        <span>
                            Content Library
                        </span>
                    </li>
                </Link>
            </ul>
        </nav>
    )
} 