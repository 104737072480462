import { StatementHeader } from "../../../components/Header/HeaderStatement"
import { useState } from "react"
import { NextButtonBlack } from "../../../components/Buttons/NextButtonBlack";
import { useNavigate } from "react-router-dom";
import { setCompleted } from "../../../services";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { TextareaAutosize } from "@mui/material";
import { getPositioningStatement, submitPositioningStatement } from "../../../services/statementsService";
import { FinishDialog } from "../../../components/Dialog/FinishDialog";

export const PositioningStatement = () => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({
        positioningStatement: ''
    });

    useEffect(() => {
        getPositioningStatement().then(response => {
            const data = response.data;
            setInitialValues({
                positioningStatement: data
            })
        }).catch(err => {
            console.log(err);
        })
    }, []);

    const _handleSubmit = values => {
        submitPositioningStatement(values).then(response => {
            setIsDialogOpen(true)
        }).catch(err => {
            console.log(err);
        })
    }

    const handleClick = () => {
        setCompleted().then(response => {
            navigate("/results");
        }).catch(error => {
            console.log(error);
        })
        setIsDialogOpen(!isDialogOpen)
    }

    return (
        <main className="position statement has-yellow-bg">
            <div className="container">
                <FinishDialog open={isDialogOpen}>
                    <h2>Congratulations! You have finished the journey. When you click the Finish button, your submissions will be locked in,
                        and you will be redirected to the Results page.</h2>
                    <button className="btn" onClick={handleClick}>Finish</button>
                </FinishDialog>
                <StatementHeader step="Positioning" />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={_handleSubmit}
                >
                    <Form>
                        <Field
                            name="positioningStatement"
                            placeholder="Your positioning statement is being generated. Please wait."
                            as={TextareaAutosize}
                        />
                        <footer className="statement__footer">
                            <div className="statement__footer-container">
                                <span>
                                    Next
                                </span>
                                <button className="btn" type="submit">
                                    <NextButtonBlack />
                                </button>
                            </div>
                        </footer>
                    </Form>
                </Formik>
            </div>
        </main>
    )
}