/**
 * Requests to handle GET and POST requests for {Purpose, Vision, Positioning} Statements
 */

import { axiosInstance as axios } from "./axiosInstance";

const URL = `/api/v2/statements`

export const getPurposeStatement = () => {
    return axios.get(`${URL}/purpose`);
}

export const submitPurposeStatement = purposeStatement => {
    return axios.post(`${URL}/purpose`, purposeStatement);
}

export const getVisionStatement = () => {
    return axios.get(`${URL}/vision`);
}

export const submitVisionStatement = visionStatement => {
    return axios.post(`${URL}/vision`, visionStatement);
}

export const getPositioningStatement = () => {
    return axios.get(`${URL}/positioning`);
}

export const submitPositioningStatement = positioningStatement => {
    return axios.post(`${URL}/positioning`, positioningStatement);
}