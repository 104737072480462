import { useState } from "react";
import { useEffect } from "react";
import { tone } from "../../services/resultsService";
import { Step } from "./Step";
import { v4 as uuidv4 } from 'uuid';

export const ResultsTone = () => {
    const [content, setContent] = useState({
        tone: [
            {
                toneAttribute: "Tone"
            },
            {
                toneAttribute: "Tone"
            },
            {
                toneAttribute: "Tone"
            },
        ],
        paragraph: 'Content is being loaded'
    });

    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "06",
        section: "Personality and Charisma - Tone of Voice",
        company: companyName
    }

    useEffect(() => {
        tone().then(response => {
            const {tone, company} = response.data;
            if(tone) {
                setContent(JSON.parse(tone))
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <Step header={header} values>
            <ul className="results__list">
                {content.tone.map(el => (
                    <li className="value" key={uuidv4()}>
                        {el.toneAttribute}
                    </li>
                ))}
            </ul>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}