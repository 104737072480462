import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"

export const StepOne = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/c0ih5au8dscl0pkzyoonp/8-The-people-you-love-helpign.mp4?rlkey=6llxhirwznaok9i45dme674t5&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    {!props.isFilled && (
                        <span className="formCount">2.</span>
                    )}
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">The People</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                When you look back at the times when you were passionate
                                of what you were doing, who were the people that you were
                                helping? Were they your community, entrepreneurs, teams,
                                people in need? How do you describe their personality?
                            </p>
                            :
                            <></>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="people1" {...props}>
                                {({ push }) => (
                                    <div>
                                        {props.values.people1.length > 0 &&
                                            props.values.people1.map((people, index) => (
                                                <Field
                                                    name={`people1.${index}.people`}
                                                    placeholder="Personality trait / Adjective of those people"
                                                    className="has-border-bottom"
                                                    disabled={props.isFilled}
                                                    key={index}
                                                />
                                            ))}
                                        {!props.isFilled && (
                                            <AddMore onClick={() => push({ people: '' })} />
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}