import { useNavigate } from "react-router-dom"
import { StatementHeader } from "../../../components/Header/HeaderStatement"
import { NextButtonBlack } from "../../../components/Buttons/NextButtonBlack"
import { useEffect, useState } from "react"
import { Field, Formik, Form } from "formik"
import { TextareaAutosize } from "@mui/material"
import { getVisionStatement, submitVisionStatement } from "../../../services/statementsService"
import { useSelector } from "react-redux"
export const VisionStatement = () => {
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        visionStatement: ''
    });

    useEffect(() => {
        getVisionStatement().then(resp => {
            const data = resp.data;
            setInitialValues({ visionStatement: data })
        })
    }, []);

    const _handleSubmit = values => {
        submitVisionStatement(values).then(response => {
            navigate("/flow/personality/introduction");
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <main className="vision statement has-yellow-bg">
            <div className="container has-yellow-bg">
                <StatementHeader step="Vision" />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={_handleSubmit}
                >
                    <Form>
                        <Field
                            name="visionStatement"
                            placeholder="The Vision Statement is being generated, note that this might take a bit. Grab another cup of coffee."
                            as={TextareaAutosize}
                        />
                        <footer className="statement__footer">
                            <div className="statement__footer-container">
                                <span>
                                    Next chapter: <br />
                                    Personality & Charisma
                                </span>
                                <button className="btn" type="submit">
                                    <NextButtonBlack />
                                </button>
                            </div>
                        </footer>
                    </Form>
                </Formik>
            </div>
        </main>
    )
}