const prod = {
    URL: 'https://api.skinsagency.com',
    stripe_secret: "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"
}

const dev = {
    URL: 'http://127.0.0.1:8000',
    // URL: 'https://api.skinsagency.com',
    stripe_secret: 'sk_test_51OCJyfF6qwJIqiayQNs    kcwvOFakJfrlsLd9A36FTilmF9h0UqgTwnLDnebwdPWLPLeMElIlRdulPHoUu7bDDnXb700dcQnft8F',
}


let getConfig = () => {
    switch(process.env.NODE_ENV) {
        case "production":
            return prod;
        case "development":
            return dev;
        default:
            return dev;
    }
};

const config = getConfig();

export default config;