import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"

export const StepOne = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/qrz00trtupp5mmvax59iu/11-The-Benefit.mp4?rlkey=7hmvuttmkt5vv640ooqdzwmai&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    {!props.isFilled && (
                        <span className="formCount">3.</span>
                    )}
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">Impact/Benefit</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                What kind of benefit did the people you served get from you?
                            </p>
                            :
                            <></>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="impactBenefit1" {...props}>
                                {({ push }) => (
                                    <div>
                                        {props.values.impactBenefit1.length > 0 &&
                                            props.values.impactBenefit1.map((proud, index) => (
                                                <Field
                                                    name={`impactBenefit1.${index}.impactBenefit`}
                                                    placeholder="Impact or Benefit"
                                                    className="has-border-bottom"
                                                    disabled={props.isFilled}
                                                    key={index}
                                                    as={TextareaAutosize}
                                                    // value={undefined}
                                                />
                                            ))}
                                        {!props.isFilled && (
                                            <AddMore onClick={() => push({ impactBenefit: '' })} />
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}