import { axiosInstance as axios } from "./axiosInstance";

const URL = `/api/results`

export const purpose = () => {
    return axios.get("/api/results/purpose");
}

export const vision = () => {
    return axios.get("/api/results/vision");
}

export const values = () => {
    return axios.get("/api/results/values");
}

export const positioning = () => {
    return axios.get("/api/results/positioning");
}

export const look = () => {
    return axios.get("/api/results/look");
}

export const tone = () => {
    return axios.get("/api/results/tone");
}

export const brand = () => {
    return axios.get("/api/results/brand");
}

export const competition = () => {
    return axios.get("/api/results/competition");
}

export const trends = () => {
    return axios.get("/api/results/trends");
}

export const content = () => {
    return axios.get("/api/results/content");
}

export const generate = (month) => {
    return axios.post(`${URL}/content`, month)
}