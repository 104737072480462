import { Slider, styled } from "@mui/material"

export const DiscreteSlider = (props) => {

    const CustomSlider = props => {
        return (
            <Slider {...props}/>
        )
    }

    const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

    const StyledSlider = styled(CustomSlider)(({theme}) => ({
        color: theme.palette.mode === 'dark' ? '#fce816' : '#fce816',
        height: 2,
        padding: '15px 0',
        '& .MuiSlider-thumb': {
            height: 20,
            width: 20,
            backgroundColor: '#fce816',
            boxShadow: iOSBoxShadow,
            '&:focus, &:hover, &.Mui-active': {
                boxShadow:
                    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    boxShadow: iOSBoxShadow,
                },
            },
        },
        '& .MuiSlider-valueLabel': {
            fontSize: 12,
            fontWeight: 'normal',
            top: -6,
            backgroundColor: 'unset',
            color: theme.palette.text.primary,
            '&:before': {
                display: 'none',
            },
            '& *': {
                background: 'transparent',
                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
            },
        },
        '& .MuiSlider-track': {
            border: 'none',
            backgroundColor: '#bfbfbf',
            opacity: 0.5,
        },
        '& .MuiSlider-rail': {
            opacity: 0.5,
            backgroundColor: '#bfbfbf',
        },
        '& .MuiSlider-mark': {
            backgroundColor: '#bfbfbf',
            height: 20,
            width: 1,
            '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: '#bfbfbf',
            },
        },
    }))

    return <StyledSlider {...props}/>
}