import { Link, Navigate } from "react-router-dom";
import ReactPlayer from "react-player/file";
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar";
import { useState } from "react";

export const PurposeIntroduction = () => {
    const [shouldIncludeAnimation, setShouldIncludeAnimation] = useState(false);

        return (
            <main className={"purpose has-black-bg container"}>
                <div className={shouldIncludeAnimation ? "enterAnimation" : ""}>
                    <div className="video-container">
                        <ReactPlayer
                            url="https://www.dropbox.com/scl/fi/d4mwyl3fqdraqyds9he85/4-Purpose.mp4?rlkey=t7yqgsp2cf5pd8xba77v73c02&raw=1"
                            playing
                            controls
                            className='react-player'
                            width="100%"
                            height="100%" />
                    </div>
                    <div className="stepper__buttons">
                        <Link to={"/flow/intro?step=2"}>
                            <span className="outlined-cta">
                                Back
                            </span>
                        </Link>
                        <Link to={"/flow/purpose/passion"}>
                            <span className="yellow-cta">
                                Next
                            </span>
                        </Link>
                    </div>
                </div>
                <HeaderSidebar activeEl="Purpose" />
            </main>
        )
}