import { Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { submitToneForm } from "../../../services";
import { useSelector } from "react-redux";
import { StepFour } from "./StepFour";
import { StepFive } from "./StepFive";
import { getToneForm } from "../../../services/autofillService";
import { Box } from "../../../components/Progress/Box";
import { StepperButtons } from "../../../components/Stepper/Buttons";

const steps = [
    'Tone of Voice',
    'Tone of Voice',
    'Tone of Voice',
    'Companies',
    'People'
];

export const ToneSteps = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [initialValues, setInitialValues] = useState({
        allToneAttributes: [
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            }
        ],
        fiveToneAttributes: [
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            }
        ],
        threeToneAttributes: [
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            },
            {
                toneAttribute: '',
            }
        ],
        companiesThatSoundLikeThat: [
            {
                companyThatSoundsLikeThat: '',
            },
            {
                companyThatSoundsLikeThat: '',
            }
        ],
        peopleThatSoundLikeThat: [
            {
                peopleThatSoundLikeThat: ''
            },
            {
                peopleThatSoundLikeThat: ''
            }
        ],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [enteringStep, setEnteringStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _renderStepContent = (step, values, enteringStep) => {
        switch (step) {
            case 0:
                return (
                    <section className="look tone col">
                        <div className="flex startAligned gap-1">
                            <StepOne
                                initialValues={initialValues}
                                isActive
                                values={values}
                                enteringStep={enteringStep === 0 ? "enter" : ""}
                            />
                            <StepTwo initialValues={initialValues} isActive={false} values={values} />
                            <StepThree initialValues={initialValues} isActive={false} values={values} />
                        </div>
                        <div className="flex">
                            <StepFour
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                            <StepFive
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                        </div>
                    </section>
                )
            case 1:
                return (
                    <section className="look tone col">
                        <div className="flex startAligned gap-1">
                            <StepOne initialValues={initialValues} isActive isFilled values={values} />
                            <StepTwo
                                initialValues={initialValues}
                                isActive
                                values={values}
                                enteringStep={enteringStep === 1 ? "enter" : ""}
                            />
                            <StepThree initialValues={initialValues} isActive={false} values={values} />
                        </div>
                        <div className="flex">
                            <StepFour
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                            <StepFive
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                        </div>
                    </section>
                )
            case 2:
                return (
                    <section className="look tone col">
                        <div className="flex startAligned gap-1">
                            <StepOne initialValues={initialValues} isActive isFilled values={values} />
                            <StepTwo initialValues={initialValues} isActive isFilled values={values} />
                            <StepThree
                                initialValues={initialValues}
                                isActive
                                values={values}
                                enteringStep={enteringStep === 2 ? "enter" : ""}
                            />
                        </div>
                        <div className="flex">
                            <StepFour
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                            <StepFive
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                            />
                        </div>
                    </section>
                );
            case 3:
                return (
                    <section className="look tone col">
                        <div className="flex startAligned gap-1 py-2">
                            <StepOne
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                            <StepTwo
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                            <StepThree
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                        </div>
                        <div className="flex secondRow">
                            <StepFour
                                isActive
                                initialValues={initialValues}
                                values={values}
                                enteringStep={enteringStep === 3 ? "enter" : ""}
                            />
                            <StepFive
                                isActive={false}
                                initialValues={initialValues}
                                values={values}
                                enteringStep={enteringStep === 4 ? "enter" : ""}
                            />
                        </div>
                    </section>
                )
            case 4:
                return (
                    <section className="look col">
                        <div className="flex startAligned gap-1 py-2">
                            <StepOne
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                            <StepTwo
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                            <StepThree
                                initialValues={initialValues}
                                isActive
                                isFilled
                                values={values}
                                secondRowIsVisible
                            />
                        </div>
                        <div className="flex secondRow gap-1">
                            <StepFour
                                isActive
                                initialValues={initialValues}
                                isFilled
                                values={values}
                            />
                            <StepFive
                                isActive
                                initialValues={initialValues}
                                values={values}
                                enteringStep={enteringStep === 4 ? "enter" : ""}
                            />
                        </div>
                    </section>
                );

            default:
                return <div>Something went wrong</div>
        }
    }

    const _submitForm = values => {
        setIsLoading(true);
        submitToneForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/personality/attributes")
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }

    const _handleSubmit = values => {
        if (isLastStep) {
            _submitForm(values)
        } else {
            setActiveStep(activeStep + 1);
            navigate(`?step=${activeStep + 1}`)
            setEnteringStep(activeStep + 1);
        }
    }

    const _handleBack = () => {
        setActiveStep(activeStep - 1);
        navigate(`?step=${activeStep - 1}`)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParams = searchParams.get('step');
        const parsedStep = parseInt(stepParams, 10);

        if (!isNaN(parsedStep) && parsedStep > 0) {
            setActiveStep(parsedStep);
        }
        getToneForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data);
            }
        })
    }, [])

    return (
        <section className={activeStep >= 3 ? "tone passion formsWrapper fullWidth" : "tone passion formsWrapper"}>
            {activeStep === steps.length ? (
                <>You have finished this section</>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                >
                    {({ values }) => (
                        <Form>
                            <Box step={activeStep} steps={steps.length} />
                            {_renderStepContent(activeStep, values, enteringStep)}
                            <StepperButtons
                                activeStep={activeStep}
                                link="/flow/personality/look?step=4"
                                handleBack={_handleBack}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </section>
    )
}