import { useEffect, useState } from "react";
import { HeaderResults } from "../../components/Header/HeaderResults"
import { ResultsSidebar } from "../../components/Header/ResultsSidebar"
import { CustomDialog } from "../../components/Dialog/CustomDialog";
import { getCompleted } from "../../services";

export const Step = ({
    header,
    children,
    values,
}) => {

    const className = header.section.trim().replaceAll(" ", "-").toLowerCase();

    const [completed, setCompleted] = useState(false);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        getCompleted().then(response => {
            const data = response.data;
            if(data === 0) {
                setCompleted(false);
                setIsOpen(true);
            } else {
                setCompleted(true);
                setIsOpen(false);
            }
        }).catch(err => {
            console.error(err);
        })
    }, []);

    return (
        <>
            {completed ? (
                <section className={`results container ${className}`}>
                    <HeaderResults
                        index={header.index}
                        sectionName={header.section}
                        name={header.company}
                    />
                    <article className="results__step">
                        <section className={values ? "results__content values" : "results__content"}>
                            {children}
                        </section>
                        <ResultsSidebar activeEl={header.section} />
                    </article>
                </section>
            ) : (
                <CustomDialog open={isOpen}/>
            )}
        </>
    )
}