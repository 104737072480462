import { useEffect, useState } from "react"
import { Step } from "./Step"
import { content, generate } from "../../services/resultsService";
import { Field, Formik, Form } from "formik";
import { v4 as uuidv4 } from 'uuid';
import { Oval } from "react-loader-spinner";
import * as Yup from "yup";

export const ResultsContent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [contentCalendar, setContentCalendar] = useState([
        {
            date: 'January 1',
            post_idea: 'Sample Post Idea',
            caption: 'Sample Caption'
        }
    ]);

    const [companyName, setCompanyName] = useState("Your Company");

    const initialValues = {
        month: ''
    }

    const header = {
        index: "10",
        section: "Content",
        company: companyName
    }

    const _handleSubmit = (values) => {
        setIsLoading(true);
        generate(values).then(response => {
            setIsLoading(false);
            const { calendar } = response.data;
            const data = JSON.parse(calendar).calendar;
            if (data) {
                setContentCalendar(data)
            }
        }).catch(err => {
            setIsLoading(false);
            console.error(err);
        })
    }

    const ContentSchema = Yup.object().shape({
        month: Yup.string()
            .matches(/^(January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/,
                { message: "Value must be the same format as the placeholder." })
            .required("Please insert a value.")
    });

    useEffect(() => {
        content().then(response => {
            const { company, calendar } = response.data;
            const data = JSON.parse(calendar).calendar;
            if (data) {
                setContentCalendar(data)
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.error(err);
        })
    }, []);

    return (
        <Step header={header}>
            <table>
                <thead>
                    <tr>
                        <td className="date">Date</td>
                        <td className="post">Post Idea</td>
                        <td className="caption">Caption</td>
                    </tr>
                </thead>
                <tbody>
                    {contentCalendar.map(el => (
                        <tr key={uuidv4()}>
                            <td className="date">{el.date}</td>
                            <td className="post">{el.post_idea}</td>
                            <td className="caption">{el.caption}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Formik
                initialValues={initialValues}
                onSubmit={_handleSubmit}
                enableReinitialize
                validationSchema={ContentSchema}
            >
                {({ errors, touched }) => (
                    <Form>
                        <fieldset className="flex gap-8 items-end">
                            <label className="text-lg">Generate New Content For:</label>
                            <Field
                                name="month"
                                className="has-border-bottom"
                                autoComplete="off"
                                placeholder="September 2024" />
                            {errors.month && touched.month ? (
                                <p className="required has-red-text">{errors.month}</p>
                            ) : null}
                            <button className="btn yellow-cta" type="submit">
                                {isLoading ? (
                                    <Oval
                                        height={30}
                                        width={50}
                                        color="black"
                                        secondaryColor="black"
                                    />
                                ) : (
                                    <span className="text-black">
                                        Generate
                                    </span>
                                )}
                            </button>
                        </fieldset>
                    </Form>
                )}
            </Formik>
        </Step>
    )
}