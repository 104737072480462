export const NextButtonBlack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198">
            <g transform="translate(-1582 -763)">
                <g transform="translate(1582 763)" fill="none" stroke="#000" strokeWidth="5">
                    <circle cx="99" cy="99" r="99" stroke="none" />
                    <circle cx="99" cy="99" r="96.5" fill="none" />
                </g>
                <g transform="translate(1045.5 929.216) rotate(-90)">
                    <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                    <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                    <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                </g>
            </g>
        </svg>
    )
}