import { useState } from "react";
import { useEffect } from "react";
import { look } from "../../services/resultsService";
import { v4 as uuidv4 } from 'uuid';
import { Step } from "./Step";

export const ResultsLook = () => {
    const [content, setContent] = useState({
        look: [
            {
                lookAndFeelAttribute: "Look and Feel"
            },
            {
                lookAndFeelAttribute: "Look and Feel"
            },
            {
                lookAndFeelAttribute: "Look and Feel"
            }
        ],
        paragraph: 'Content is being loaded.'
    })

    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "05",
        section: "Personality and Charisma - Look and Feel",
        company: companyName
    }

    useEffect(() => {
        look().then(response => {
            const {look, company} = response.data;
            if(look) {
                setContent(JSON.parse(look))
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <Step header={header} values>
            <ul className="results__list">
                {content.look.map(el => (
                    <li className="value" key={uuidv4()}>
                        {el.lookAndFeelAttribute}
                    </li>
                ))}
            </ul>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}