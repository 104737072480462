import './App.scss';
import { Route, Routes } from "react-router-dom";
import { Welcome } from './components/Welcome/Welcome';
import { Login } from './components/Welcome/Login/Login';
import { Register } from './components/Register/Register';
import { Introduction } from './pages/Introduction/Introduction';
import { NotFound } from './pages/NotFound/NotFound';
import { Intro } from './pages/Flow/Intro/Intro';
import { PurposeIntroduction } from './pages/Flow/Purpose/Introduction';
import { PurposePassion } from "./pages/Flow/Purpose/PurposePassion";
import { Results } from './pages/Results/Results';
import { PurposePeople } from './pages/Flow/Purpose/PurposePeople';
import { PurposeImpact } from './pages/Flow/Purpose/PurposeImpact';
import { PurposeProduct } from './pages/Flow/Purpose/PurposeProduct';
import { PurposeStatement } from './pages/Flow/Purpose/PurposeStatement';
import { Vision } from './pages/Flow/Vision/Vision';
import { VisionStatement } from './pages/Flow/Vision/VisionStatement';
import { PersonalityIntroduction } from './pages/Flow/Personality/PersonalityIntroduction';
import { RelationshipsCompetition } from './pages/Flow/Relationships/RelationshipsCompetition';
import { PersonalityLook } from './pages/Flow/Personality/PersonalityLook';
import { PersonalityValues } from './pages/Flow/Personality/PersonalityValues';
import { PersonalityTone } from './pages/Flow/Personality/PersonalityTone';
import { PersonalityAttributes } from './pages/Flow/Personality/PersonalityAttributes';
import { RelationshipsTrends } from './pages/Flow/Relationships/RelationshipsTrends';
import { PositioningStatement } from './pages/Flow/Positioning/PositioningStatement';
import { Checkout } from './pages/Checkout/Checkout';
import { ProtectedRoute } from './components/Routes/ProtectedRoute';
import { NotPaid } from './pages/NotPaid/NotPaid';
import { ForgotPassword } from './pages/Password/ForgotPassword';
import { ResultsPurpose } from './steps/results/ResultsPurpose';
import { ResultsVision } from './steps/results/ResultsVision';
import { ResultsValues } from './steps/results/ResultsValues';
import { ResultsPositioning } from './steps/results/ResultsPositioning';
import { ResultsLook } from './steps/results/ResultsLook';
import { ResultsTone } from './steps/results/ResultsTone';
import { ResultsAttributes } from './steps/results/ResultsAttributes';
import { ResultsCompetition } from './steps/results/ResultsCompetition';
import { ResultsContent } from './steps/results/ResultsContent';
import { ResultsTrends } from './steps/results/ResultsTrends';

function App() {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/register' element={<Register />} />

      <Route path='*' element={<NotFound />} />

      <Route path='/payment-failed' element={<NotPaid />} />

      <Route path="/"
        element={
          <ProtectedRoute shouldCheckCompleted shouldCheckPayment={false}>
            <Welcome />
          </ProtectedRoute>
        }
      />

      <Route path='/results' element={<Results />} />
      <Route path='/results/purpose' element={<ResultsPurpose />} />
      <Route path='/results/vision' element={<ResultsVision />} />
      <Route path='/results/values' element={<ResultsValues />} />
      <Route path='/results/positioning' element={<ResultsPositioning />} />
      <Route path='/results/look' element={<ResultsLook />} />
      <Route path='/results/tone' element={<ResultsTone />} />
      <Route path='/results/attributes' element={<ResultsAttributes />} />
      <Route path='/results/competition' element={<ResultsCompetition />} />
      <Route path='/results/trends' element={<ResultsTrends />} />
      <Route path='/results/content' element={<ResultsContent />} />
      
      <Route path='/introduction'
        element={
          <ProtectedRoute shouldCheckCompleted shouldCheckPayment={false}>
            <Introduction />
          </ProtectedRoute>
        } />

      <Route path='/flow/intro'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <Intro />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposeIntroduction />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose/passion'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposePassion />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose/people'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposePeople />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose/impact'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposeImpact />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose/product'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposeProduct />
          </ProtectedRoute>
        } />

      <Route path='/flow/purpose/statement'
        element={
          <ProtectedRoute shouldCheckCompleted>
            <PurposeStatement />
          </ProtectedRoute>
        } />

      <>
        <Route path='/flow/vision'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <Vision />
            </ProtectedRoute>
          } />
        <Route path='/flow/vision/statement'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <VisionStatement />
            </ProtectedRoute>
          } />
        <Route path='/flow/personality/introduction'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PersonalityIntroduction />
            </ProtectedRoute>
          }
        />

        <Route path='/flow/personality/values'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PersonalityValues />
            </ProtectedRoute>
          }
        />

        <Route path='/flow/personality/look'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PersonalityLook />
            </ProtectedRoute>
          }
        />

        <Route path='/flow/personality/tone'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PersonalityTone />
            </ProtectedRoute>
          }
        />

        <Route path='/flow/personality/attributes'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PersonalityAttributes />
            </ProtectedRoute>
          }
        />

        <Route path='/flow/relationships/competition'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <RelationshipsCompetition />
            </ProtectedRoute>} />

        <Route path='/flow/relationships/trends'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <RelationshipsTrends />
            </ProtectedRoute>} />

        <Route path='/flow/positioning'
          element={
            <ProtectedRoute shouldCheckPayment shouldCheckCompleted>
              <PositioningStatement />
            </ProtectedRoute>} />
      </>
      <Route path='/checkout' element={<Checkout />} />
    </Routes>
  );
}

export default App;
