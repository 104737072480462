import { Form, Formik, Field } from "formik"
import * as Yup from "yup";

export const ForgotPassword = () => {
    const initialValues = {
        email: '',
    }

    const handleSubmit = values => {
        console.log(values);
    }

    const EmailSchema = Yup.object().shape({
        email: Yup.string().email().required("Email is required.")
    })

    return (
        <main className="forgot has-black-bg has-full-height">
            <Formik
                initialValues={initialValues}
                onSubmit={values => handleSubmit(values)}
                validationSchema={EmailSchema}
            >

                {({ errors, touched }) => (
                    <Form>
                        <fieldset className="flex-col gap-3">
                            <Field
                                name="email"
                                type="text"
                                className="has-border-bottom"
                                placeholder="Email"
                                autoComplete="off" />
                            {errors.email && touched.email ? (
                                <p className="required has-red-text">{errors.email}</p>
                            ) : null}
                        </fieldset>
                    </Form>
                )}
            </Formik>
        </main>
    )
}