import { Field, FieldArray, Formik, Form } from "formik"
import { useNavigate } from "react-router-dom"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import { submitTrendsForm } from "../../../services"
import { useSelector } from "react-redux"
import ReactPlayer from "react-player"
import { useEffect } from "react"
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { TextareaAutosize } from "@mui/material"
import { useState } from "react"
import { getTrendsForm } from "../../../services/autofillService"
import { Box } from "../../../components/Progress/Box"
import { StepperButtons } from "../../../components/Stepper/Buttons"

export const RelationshipsTrends = () => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        trends: [
            {
                trend: '',
            },
            {
                trend: '',
            },
            {
                trend: '',
            },
            {
                trend: '',
            },
            {
                trend: '',
            },
        ]
    });
    const [isLoading, setIsLoading] = useState(false);
    const [triggeredAnimation, setTriggeredAnimation] = useState(true);

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _handleSubmit = values => {
        setIsLoading(true);
        submitTrendsForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/positioning");
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getTrendsForm().then(response => {
            const data = response.data;
            if(data) {
               setInitialValues(data);
            }
        })
    }, [])

    return (
        <div className="wrapper">
            <main className="relationships has-black-bg container">
                <section className="passion formsWrapper">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        enableReinitialize
                    >
                        {({ values }) => (
                            <Form>
                                <Box step={0} steps="1" />
                                <div className="flex">
                                <article className={triggeredAnimation ? "form__container enter" : "form__container"}>
                                        <div className="purpose__video">
                                            <ReactPlayer
                                                url="https://www.dropbox.com/scl/fi/kopqpb57vyote72b7y0x0/28-Market-Trends.mp4?rlkey=0woci39ktml7s9q5ayiafqo7n&raw=1"
                                                controls
                                                className='react-player'
                                                width="100%"
                                                height="100%"
                                            />
                                        </div>
                                        <section className="form__question">
                                            <span className="formCount">2.</span>
                                            <div className="form__wrapper">
                                                <h3 className="form__title">What are the market trends in the next 5 years?</h3>
                                                <p className="form__description">
                                                    Write core market trends that are expected to be vital to
                                                    your business in the coming years. This will help your team focus
                                                    their attention around the right information and help you continuously
                                                    stay relevant.
                                                </p>
                                                <fieldset className="flex-col">
                                                    <FieldArray name="trends">
                                                        {({ insert, remove, push }) => (
                                                            <div>
                                                                {values.trends.length > 0 &&
                                                                    values.trends.map((trend, index) => (
                                                                        <Field
                                                                            name={`trends.${index}.trend`}
                                                                            placeholder="Market Trend"
                                                                            className="has-border-bottom"
                                                                            key={index}
                                                                            as={TextareaAutosize}
                                                                            // value={undefined}
                                                                        />
                                                                    ))}
                                                                <AddMore onClick={() => push({ trend: '' })} />
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </fieldset>
                                            </div>
                                        </section>
                                    </article>
                                    <StepperButtons 
                                        link="/flow/relationships/competition"
                                        isLoading={isLoading}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </main>
            <HeaderSidebar activeEl="Relationships" />
        </div>
    )
}