import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

export const CustomDialog = ({...props}) => {
    const CustomDialog = styled(Dialog)`
    .MuiPaper-root {
        background-color: #FCE816;
        padding: 2em;
    }
    `

    return (
        <CustomDialog {...props }>
            <div className="dialog__wrapper results">
                <h2 className="text-black font-medium text-xl">You can't view this page without completing the questionnaire first.</h2>
                <a href="/">Go to the Main Page.</a>
            </div>
        </CustomDialog>
    )
}


