export const AddMore = (props) => {
    return (
        <button className="btn addMore" type="button" onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
                <g transform="translate(-838 -813)">
                    <g transform="translate(838 813)">
                        <g transform="translate(41) rotate(90)" fill="none" stroke="#c7c7ba" strokeWidth="2">
                            <circle cx="20.5" cy="20.5" r="20.5" stroke="none" />
                            <circle cx="20.5" cy="20.5" r="19.5" fill="none" />
                        </g>
                        <g transform="translate(-838 -696)">
                            <g transform="translate(0 71)">
                                <g transform="translate(0.5 0.5)">
                                    <line y2="16" transform="translate(858.5 637.5)" fill="none" stroke="#c7c7ba" strokeLinecap="round" strokeWidth="2" />
                                    <line y2="16" transform="translate(866.5 645.5) rotate(90)" fill="none" stroke="#c7c7ba" strokeLinecap="round" strokeWidth="2" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    )
}