import { Field, Form, Formik, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signUp } from "../../services";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { images } from "../../assets/images";

export const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    const initialValues = {
        name: '',
        surname: '',
        website: '',
        email: '',
        password: '',
    }
    const handleSubmit = values => {
        signUp(values.name, values.surname, values.website, values.email, values.password)
            .then(resp => {
                const returnToUrl = new URLSearchParams(location.search).get("returnUrl");
                navigate(returnToUrl || `/`);
            }).catch(err => {
                const data = err.response.data.message;
                setErrorMessage(data);
            })
    }
    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required("Required"),
        surname: Yup.string().required("Required"),
        website: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
    })
    return (
        <main className="login has-black-bg has-full-height">
            <header className="login__header">
                <a href="https://skinsagency.com">
                    <img src={images.logo} alt="logo"/>
                </a>
            </header>
            <div className="container">
                <section className="formWrapper">
                    <article className="form__container">
                        <h1 className="form__title">Register</h1>
                        {errorMessage !== "" ? (
                            <p className="has-yellow-text">
                                {errorMessage}
                            </p>
                        ) : (
                            <></>
                        )}
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => handleSubmit(values)}
                            autoComplete="off"
                            validationSchema={RegisterSchema}
                        >
                            {({ errors, touched }) => (

                                <Form method="POST" autoComplete="off">
                                    <fieldset className="flex-col">
                                        <Field
                                            name="name"
                                            type="text"
                                            className="has-border-bottom"
                                            placeholder="Name"
                                            autoComplete="off" />
                                        {errors.name && touched.name ? (
                                            <p className="required has-red-text">{errors.name}</p>
                                        ) : null}
                                    </fieldset>
                                    <fieldset className="flex-col">
                                        <Field
                                            name="surname"
                                            type="text"
                                            className="has-border-bottom"
                                            placeholder="Surname"
                                            autoComplete="off" />
                                        {errors.surname && touched.surname ? (
                                            <p className="required has-red-text">{errors.surname}</p>
                                        ) : null}
                                    </fieldset>
                                    <fieldset className="flex-col">
                                        <Field
                                            name="website"
                                            type="text"
                                            className="has-border-bottom"
                                            placeholder="Website"
                                            autoComplete="off" />
                                        {errors.website && touched.website ? (
                                            <p className="required has-red-text">{errors.website}</p>
                                        ) : null}
                                    </fieldset>
                                    <fieldset className="flex-col">
                                        <Field
                                            name="email"
                                            type="email"
                                            className="has-border-bottom"
                                            placeholder="Email"
                                            autoComplete="off" />
                                        {errors.email && touched.email ? (
                                            <p className="required has-red-text">{errors.email}</p>
                                        ) : null}
                                    </fieldset>
                                    <fieldset className="flex-col">
                                        <Field
                                            name="password"
                                            type="password"
                                            className="has-border-bottom"
                                            placeholder="Password"
                                            autoComplete="off" />
                                        {errors.password && touched.password ? (
                                            <p className="required has-red-text">{errors.password}</p>
                                        ) : null}
                                    </fieldset>
                                    {/* <fieldset className="flex-col">
                                        <Field
                                            name="confirmPassword"
                                            type="text"
                                            className="has-border-bottom"
                                            placeholder="Confirm your password"
                                            autoComplete="off" />
                                        {errors.confirmPassword && touched.confirmPassword ? (
                                            <p className="required has-red-text">{errors.confirmPassword}</p>
                                        ) : null}
                                    </fieldset> */}
                                    <div className="terms__container">
                                        <input type="checkbox" value="terms"/>
                                        <label htmlFor="terms"> I have read and accept <a target="_blank" rel="noreferrer" href="https://skinsagency.com/terms-and-conditions" style={{textDecoration: "underline"}}>terms and conditions</a> and <a target="_blank" rel="noreferrer" href="https://skinsagency.com/privacy-policy" style={{textDecoration: "underline"}}>privacy policy</a>.</label>
                                    </div>
                                    <div className="register welcome-cta">
                                        <button className="btn" type="submit">
                                            <span>
                                                Register
                                                <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198">
                                                    <g transform="translate(-489 -763)">
                                                        <g transform="translate(489 763)" fill="none" stroke="#fce816" strokeWidth="5">
                                                            <circle cx="99" cy="99" r="99" stroke="none" />
                                                            <circle cx="99" cy="99" r="96.5" fill="none" />
                                                        </g>
                                                        <g transform="translate(-47.5 929.216) rotate(-90)">
                                                            <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                                            <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                                            <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div>
                                        <a href="/login">
                                            Have an account? Log in.
                                        </a>
                                    </div>
                                </Form>
                            )}

                        </Formik>
                    </article>
                </section>
            </div>
        </main>
    )
}