import { TextareaAutosize } from "@mui/material"
import { Field, FieldArray } from "formik"
import ReactPlayer from "react-player"

export const StepTwo = (props) => {
    const handleArticleClassName = () => {
        if(props.isFilled) return "form__container isFilled exit";
        if(props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                {/* <span className="formCount">1.1</span> */}
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/yhnd48kaa0s4j246gsbcz/17-The-Vision.mp4?rlkey=vl5fhsvsotxvwzskprbdye9wr&raw=1"
                        playing
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">What will the world look like when your company
                                has reached its full potential?</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                As your company has reached that goal, and its full effects have taken on in the world, what will the world look like?
                                The easiest way to do this is to start the sentence with: "When we've reached our full potential, the world will…"
                            </p>
                            :
                            <p className="form__description">{"\n"}</p>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="worldPotential" {...props}>
                                <div>
                                    {props.initialValues.worldPotential.length > 0 &&
                                        props.initialValues.worldPotential.map((proud, index) => (
                                            <Field
                                                name={`worldPotential.${index}.worldPotential`}
                                                placeholder="When we've reached our full potential, the world will…"
                                                className="has-border-bottom"
                                                disabled={props.isFilled}
                                                key={index}
                                                as={TextareaAutosize}
                                                // value={undefined}
                                            />
                                        ))}
                                    {/* <AddMore onClick={handleClick(push)} /> */}
                                </div>
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}