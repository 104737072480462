import { useEffect, useState } from "react"
import { values } from "../../services/resultsService";
import { Step } from "./Step";
import { v4 as uuidv4 } from 'uuid';

export const ResultsValues = () => {
    const [content, setContent] = useState(
        {
            values: [
                { personalityValue: 'Value' },
                { personalityValue: 'Value' },
                { personalityValue: 'Value' },
            ],
            paragraph: 'Content is being loaded.'
        }
    )

    const [companyName, setCompanyName] = useState('Your Company');

    const header = {
        index: "03",
        section: "Values",
        company: companyName
    }

    useEffect(() => {
        values().then(response => {
            const { values, company } = response.data;
            setContent({...content, values: JSON.parse(values).values, paragraph: JSON.parse(values).paragraph})
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <Step header={header} values>
            <ul className="results__list">
                {content.values.map(el => (
                    <li className="value" key={uuidv4()}>{el.personalityValue}</li>
                ))}
            </ul>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}