import { Field, FieldArray, Form, Formik } from "formik"
import { useNavigate } from "react-router-dom"
import { Slider, styled } from "@mui/material"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import { submitAttributesForm } from "../../../services"
import ReactPlayer from "react-player"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { useState } from "react"
import { getAttributesForm } from "../../../services/autofillService"
import { Box } from "../../../components/Progress/Box"
import { StepperButtons } from "../../../components/Stepper/Buttons"

export function fieldToSlider({ field, form: { isSubmitting }, ...props }) {
    return {
        ...props,
        ...field,
        name: field.name,
        value: field.value
    }
}
const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';


export const CustomSlider = props => {
    return (
        <Slider {...fieldToSlider(props)} onChange={(e, value) => {
            props.form.setFieldValue(props.field.name, value);
        }} />
    )
}

const discreteSlider = styled(CustomSlider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#fce816' : '#fce816',
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fce816',
        boxShadow: iOSBoxShadow,
        '&:focus, &:hover, &.Mui-active': {
            boxShadow:
                '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        backgroundColor: '#bfbfbf',
        opacity: 0.5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 20,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: '#bfbfbf',
        },
    },
}))

const marks = [
    {
        value: 1,
    },
    {
        value: 2,
    },
    {
        value: 3,
    },
    {
        value: 4,
    },
    {
        value: 5,
    },
    {
        value: 6,
    },
    {
        value: 7,
    },
];

export const PersonalityAttributes = () => {
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        personalityAttributes: [
            {
                leftAttribute: '',
                personalityAttribute: 4,
                rightAttribute: ''
            }
        ]
    });

    const [isLoading, setIsLoading] = useState(false);
    const [triggeredAnimation, setTriggeredAnimation] = useState(true);
    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _handleSubmit = values => {
        setIsLoading(true);
        submitAttributesForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/relationships/competition");
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    useEffect(() => {
        getAttributesForm().then(response => {
            const data = response.data;
            if(data) {
                setInitialValues(data);
            }
        })
    }, [])

    return (
        <div className="wrapper">
            <main className="personality has-black-bg container">
                <section className="passion attributes formsWrapper fullWidth">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        enableReinitialize
                    >
                        {({ values }) => (
                            <Form>
                                <Box step={0} steps="1"/>
                                <div className="flex">
                                <article className={triggeredAnimation ? "form__container enter" : "form__container"}>
                                        <div className="purpose__video">
                                            <ReactPlayer
                                                url="https://www.dropbox.com/scl/fi/4yjhg2o28czbcv29i98nf/27-Brand-attributes.mp4?rlkey=unc20piq74kpny131q1mk5ecu&raw=1"
                                                playing
                                                controls
                                                className='react-player'
                                                width="100%"
                                                height="100%"
                                            />
                                        </div>
                                        <section className="form__question">
                                            <span className="formCount">4.</span>
                                            <div className="form__wrapper">
                                                <h3 className="form__title">Attribute Scale</h3>
                                                <p className="form__description">
                                                    Bring attributes that were contradictory from the previous two steps, and put them in a scale. By finding the <br />
                                                    balance between them we also create a brand personality that we can quickly describe, and use across our messaging. <br />
                                                </p>
                                                <fieldset className="flex-col">
                                                    <FieldArray name="personalityAttributes">
                                                        {({ insert, remove, push }) => (
                                                            <div>
                                                                {values.personalityAttributes.length > 0 &&
                                                                    values.personalityAttributes.map((personalityAttribute, index) => (
                                                                        <fieldset className="attributes">
                                                                            <Field
                                                                                name={`personalityAttributes.${index}.leftAttribute`}
                                                                                id="leftAttribute"
                                                                                className="attributes has-border-bottom"
                                                                                placeholder="Attribute 1"
                                                                            />
                                                                            <Field
                                                                                component={discreteSlider}
                                                                                name={`personalityAttributes.${index}.personalityAttribute`}
                                                                                id="personalityAttribute"
                                                                                defaultValue={2}
                                                                                aria-labelledby="discrete-slider-restrict"
                                                                                step={null}
                                                                                marks={marks}
                                                                                min={1}
                                                                                max={7}
                                                                            />
                                                                            <Field
                                                                                name={`personalityAttributes.${index}.rightAttribute`}
                                                                                id="rightAttribute"
                                                                                className="attributes has-border-bottom"
                                                                                placeholder="Attribute 2"
                                                                            />
                                                                        </fieldset>
                                                                    ))}
                                                                <AddMore onClick={() => push({ leftAttribute: '', personalityAttribute: null, rightAttribute: '' })} />
                                                            </div>
                                                        )}
                                                    </FieldArray>
                                                </fieldset>
                                                <StepperButtons 
                                                link="/flow/personality/tone?step=4"
                                                isLoading={isLoading}
                                                />
                                            </div>
                                        </section>
                                    </article>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </section>
            </main>
            <HeaderSidebar activeEl="Personality & Charisma" />
        </div>
    )
}