import { Oval } from "react-loader-spinner"
import { Link } from "react-router-dom"

export const StepperButtons = ({ activeStep, link, handleBack, isLoading }) => (
    <div className="stepper__buttons">
        {activeStep !== 0 && (
            <button onClick={handleBack} className="btn outlined-cta" type="button">
                Back
            </button>
        )}
        {activeStep === 0 && (
            <Link to={link} className="outlined-cta">Back</Link>
        )}
        <button type="submit" className="btn yellow-cta">
            {isLoading ? <Oval
                height={30}
                width={50}
                color="black"
                secondaryColor="black"
            /> : (
                <span>
                    Next
                </span>
            )}
        </button>
    </div>
)