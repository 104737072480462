import { useEffect, useState } from "react"
import { brand } from "../../services/resultsService";
import { Step } from "./Step";
import { DiscreteSlider } from "../../components/Sliders/DiscreteSlider";
import { v4 as uuidv4 } from 'uuid';

export const ResultsAttributes = () => {
    const [content, setContent] = useState({
        brand: [
            {
                leftAttribute: "Attribute",
                personalityAttribute: 0,
                rightAttribute: "Attribute"
            }
        ],
        paragraph: 'Content is being loaded'
    });

    const marks = [
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
        {
            value: 5,
        },
        {
            value: 6,
        },
        {
            value: 7,
        },
    ];

    const [companyName, setCompanyName] = useState("Your Company");

    const header = {
        index: "07",
        section: "Personality and Charisma - Brand Attributes",
        company: companyName
    }

    useEffect(() => {
        brand().then(response => {
            const { brand, company } = response.data;
            if (brand) {
                setContent(JSON.parse(brand))
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(err => {
            console.log(err);
        })
    }, [])

    return (
        <Step header={header}>
            <h1 className="results__title">
                We are {content.brand[0].leftAttribute} but {content.brand[0].rightAttribute}
            </h1>
            <div className="results-attributes__container">
                {content.brand.map(el => (
                    <div className="results-attribute__container" key={uuidv4()}>
                        <h2>{el.leftAttribute}</h2>
                        <DiscreteSlider
                            defaultValue={el.personalityAttribute}
                            marks={marks}
                            min={1}
                            max={7}
                        />
                        <h2>{el.rightAttribute}</h2>
                    </div>
                ))}
            </div>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}