import { Field, FieldArray } from "formik";

export const StepFour = (props) => {
  const handleArticleClassName = () => {
    if(props.isFilled) return "form__container isFilled exit";
    if(props.enteringStep) return "form__container enter";
    else return "form__container";
}
  if (props.isActive) {
    return (
      <article className={handleArticleClassName()}>
        <div className="form__wrapper">
          <p className="form__description">
            List companies that look like that
          </p>
          <fieldset className="flex-col">
            <FieldArray name="companiesThatLookLikeThat1" {...props}>
              <div>
                {props.initialValues.companiesThatLookLikeThat1.length > 0 &&
                  props.initialValues.companiesThatLookLikeThat1.map(
                    (companyThatLookLikeThat1, index) => (
                      <Field
                        name={`companiesThatLookLikeThat1.${index}.companyThatLookLikeThat1`}
                        placeholder="Company"
                        className="has-border-bottom"
                        disabled={props.isFilled}
                        key={index}
                      />
                    )
                  )}
              </div>
            </FieldArray>
          </fieldset>
        </div>
      </article>
    );
  }
};
