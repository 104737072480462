import { Form, Formik } from "formik";
import { useEffect, useState } from "react"
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { useLocation, useNavigate } from "react-router-dom";
import { StepThree } from "./StepThree";
import { submitPeopleForm } from "../../../services";
import { useSelector } from "react-redux";
import { getPeopleForm } from "../../../services/autofillService";
import { Box } from "../../../components/Progress/Box";
import { StepperButtons } from "../../../components/Stepper/Buttons";

const steps = ['The People', 'The People', 'Combine'];

export const PeopleSteps = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [initialValues, setInitialValues] = useState({
        people1: [
            {
                people: ''
            },
            {
                people: ''
            },
            {
                people: ''
            },
            {
                people: ''
            },
        ],
        people2: [
            {
                target: ''
            },
            {
                target: ''
            },
            {
                target: ''
            },
            {
                target: ''
            },
        ],
        combine: [
            {
                combine: ''
            }
        ]
    });
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [enteringStep, setEnteringStep] = useState(0);

    const isLastStep = activeStep === steps.length - 1;

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _renderStepContent = (step, values, enteringStep) => {
        switch (step) {
            case 0:
                return (
                    <div className="startAligned flex gap-1">
                        <StepOne
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 0 ? "enter" : ""}
                        />
                        <StepTwo initialValues={initialValues} isActive={false} values={values} />
                        <StepThree initialValues={initialValues} isActive={false} values={values} />
                    </div>
                )
            case 1:
                return (
                    <div className="startAligned halfFlex flex gap-1">
                        <StepOne initialValues={initialValues} isActive isFilled values={values} />
                        <StepTwo
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 1 ? "enter" : ""}
                        />
                        <StepThree initialValues={initialValues} isActive={false} values={values} />
                    </div>
                )
            case 2:
                return (
                    <div className="startAligned flex gap-1">
                        <StepOne initialValues={initialValues} isActive isFilled values={values} />
                        <StepTwo initialValues={initialValues} isActive isFilled values={values} />
                        <StepThree
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 2 ? "enter" : ""}
                        />
                    </div>
                )
            default:
                return <div>Something went wrong</div>
        }
    }

    const _submitForm = values => {
        setIsLoading(true);
        submitPeopleForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/purpose/impact?step=0");
        }).catch(err => {
            setIsLoading(false);
             console.log(err);
        })
    }

    const _handleSubmit = values => {
        if (isLastStep) {
            _submitForm(values);
        } else {
            setActiveStep(activeStep + 1);
            navigate(`?step=${activeStep + 1}`)
            setEnteringStep(activeStep + 1);
        }
    }

    const _handleBack = () => {
        setActiveStep(activeStep - 1);
        navigate(`?step=${activeStep - 1}`)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParams = searchParams.get('step');
        const parsedStep = parseInt(stepParams, 10);

        if (!isNaN(parsedStep) && parsedStep > 0) {
            setActiveStep(parsedStep);
        }

        getPeopleForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data);
            }
        }).catch(err => {
            console.log(err);
            setInitialValues({
                companyName: '',
                problemStatement: '',
                problemSolution: ''
            })
        })
    }, [activeStep, location.search])

    return (
        <section className="people formsWrapper">
            {activeStep === steps.length ? (
                <>You have finished this section</>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                >
                    {({ values }) => (
                        <Form>
                            <Box step={activeStep} steps={steps.length} />
                            {_renderStepContent(activeStep, values, enteringStep)}
                            <StepperButtons
                                activeStep={activeStep}
                                link="/flow/purpose/passion?step=2"
                                handleBack={_handleBack}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </section>
    )
}