import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getCompletedPayment } from "../../services"

export const NotPaid = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        getCompletedPayment().then(response => {
            console.log(response.data);
        }).catch(err => {
            console.error(err);
        });

        console.log(sessionStorage.getItem('previousNav'));

    }, []);

    return (
        <main className="notFound has-full-height">
            <h1 className="has-white-text">
                <span>Uh Oh!</span>
                Please verify the payment in order to view this page.
            </h1>
            <Link to="/">Go to Main Page</Link>
        </main>
    )
}