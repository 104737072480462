import { Form, Formik } from "formik";
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { submitPassionForm } from "../../../services";
import { useSelector } from "react-redux";
import { getPassionForm } from "../../../services/autofillService";
import { Box } from "../../../components/Progress/Box";
import { StepperButtons } from "../../../components/Stepper/Buttons";

const steps = ['What made you proud', 'What you are passionate about', 'What your passion is'];

export const PassionSteps = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [enteringStep, setEnteringStep] = useState(0);
    const [initialValues, setInitialValues] = useState({
        thingsThatMadeYouProud: [
            {
                proud: ''
            },
        ],
        emergedFromCases: [
            {
                passion: '',
            },
        ],
        emergedFromSentence: [
            {
                passion: '',
            },
        ]
    })

    const isLastStep = activeStep === steps.length - 1;

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _renderStepContent = (step, values, enteringStep) => {
        // Removed value={undefined} from each step so it autofills from the API i have no idea what is going on with it.
        switch (step) {
            case 0:
                return (
                    <div className="flex gap-1 startAligned">
                        <StepOne
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 0 ? "enter" : ""}
                        />
                        <StepTwo initialValues={initialValues} isActive={false} values={values} />
                        <StepThree initialValues={initialValues} isActive={false} values={values} />
                    </div>
                )
            case 1:
                return (
                    <div className="flex gap-1 startAligned" >
                        <StepOne initialValues={initialValues} isActive isFilled values={values} />
                        <StepTwo
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 1 ? "enter" : ""}
                        />
                        <StepThree initialValues={initialValues} isActive={false} values={values} />
                    </div>
                )
            case 2:
                return (
                    <div className="flex gap-1 startAligned">
                        <StepOne initialValues={initialValues} isActive isFilled values={values} />
                        <StepTwo initialValues={initialValues} isActive isFilled values={values} />
                        <StepThree
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 2 ? "enter" : ""}
                        />
                    </div>
                )
            default:
                return <div>Something went wrong</div>
        }
    }

    const _submitForm = values => {
        setIsLoading(true);
        submitPassionForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/purpose/people?step=0")
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const _handleSubmit = values => {
        if (isLastStep) {
            _submitForm(values);
        } else {
            setActiveStep(activeStep + 1);
            navigate(`?step=${activeStep + 1}`)
            setEnteringStep(activeStep + 1);
        }
    }

    const _handleBack = () => {
        setActiveStep(activeStep - 1);
        navigate(`?step=${activeStep - 1}`)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParams = searchParams.get('step');
        const parsedStep = parseInt(stepParams, 10);

        if (!isNaN(parsedStep) && parsedStep > 0) {
            setActiveStep(parsedStep);
        }

        getPassionForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data)
            }
        })
    }, [activeStep, location.search])

    return (
        <section className="passion formsWrapper">
            {activeStep === steps.length ? (
                <>You have finished this section</>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                >
                    {({ values }) => (
                        <Form>
                            <Box step={activeStep} steps={steps.length} />
                            {_renderStepContent(activeStep, values, enteringStep)}
                            <StepperButtons
                                activeStep={activeStep}
                                link="/flow/purpose"
                                handleBack={_handleBack}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </section>
    )
}