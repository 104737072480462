import { Link } from "react-router-dom"

export const NotFound = () => {
    return (
        <main className="notFound has-full-height">
            <h1 className="has-white-text">
                <span>404</span>
                Page not found
            </h1>
            <Link to="/">Go to Main Page</Link>
        </main>
    )
}