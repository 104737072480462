import React, { useRef, forwardRef } from "react";
import { Scatter, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2";
import { Chart, LinearScale, PointElement } from "chart.js";
import { getRelativePosition } from "chart.js/helpers";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(LinearScale, PointElement, ChartDataLabels);

export const CustomChart = forwardRef(({ data }, chartRef) => {
  const options = {
    responsive: true,
    clip: false,
    animations: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: "right",
        offset: 15,
        color: "white",
        font: {
          weight: 100,
          family: "SG-Light",
        },
        borderColor: "white",
        borderTopColor: "red",
        borderWidth: 1,
        borderWidthTop: 0,
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex];
        },
      },
    },
    scales: {
      x: {
        min: -5,
        max: 5,
        type: "linear",
        position: "center",
        border: {
          color: "#707070",
        },
        grid: {
          tickColor: "#707070",
        },
        ticks: {
          display: false,
        },
      },
      y: {
        type: "linear",
        position: "center",
        min: -5,
        max: 5,
        border: {
          color: "#707070",
        },
        grid: {
          tickColor: "#707070",
        },
        ticks: {
          display: false,
        },
      },
    },
    interaction: {
      mode: "point",
      axis: "xy",
    },
    events: ["click", "mousemove", "mouseout", "touchstart", "touchmove"],
    elements: {
      point: {
        radius: 10,
        hitRadius: 10,
        hoverRadius: 10,
        backgroundColor: "white",
      },
    },
  };

  let draggingData = null;

  const handleClick = (e) => {
    const canvasPosition = getRelativePosition(e, chartRef.current);
    const dataX = Math.round(
      chartRef.current.scales.x.getValueForPixel(canvasPosition.x)
    );
    const dataY = chartRef.current.scales.y.getValueForPixel(canvasPosition.y);
    const tolerance = 1;
    draggingData = data.datasets[0].data.find((data) => {
      return (
        Math.abs(data.x - dataX) < tolerance &&
        Math.abs(data.y - dataY) < tolerance
      );
    });
  };

  const handleMouseMove = (e) => {
    const canvasPosition = getRelativePosition(e, chartRef.current);
    const dataX = Math.round(
      chartRef.current.scales.x.getValueForPixel(canvasPosition.x)
    );
    const dataY = Math.round(
      chartRef.current.scales.y.getValueForPixel(canvasPosition.y)
    );
    if (draggingData) {
      if (draggingData.x !== dataX || draggingData.y !== dataY) {
        draggingData.x = dataX;
        draggingData.y = dataY;
        chartRef.current.update();
      }
    } else {
      draggingData = null;
    }
  };

  const handleMouseOut = () => {
    draggingData = null;
  };

  const handleMouseUp = () => {
    draggingData = null;
  };

  return (
    <div className="center__attribute">
      <Scatter
        data={data}
        options={options}
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        onMouseUp={handleMouseUp}
        ref={chartRef}
        style={{
          width: 650,
          height: 400,
        }}
      />
    </div>
  );
});
