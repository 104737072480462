import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { PeopleSteps } from "../../../steps/purpose/people/PeopleSteps"

export const PurposePeople = () => {
    return (
        <div className="wrapper">
            <main className="purpose has-black-bg container">
                <PeopleSteps />
            </main>
            <HeaderSidebar activeEl="Purpose" />
        </div>
    )
}