import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { images } from "../../assets/images";

export const Welcome = (props) => {
    const contact = useSelector(state => {
        return (state.user.user.name);
    });

    return (
        <>
            <main className="has-black-bg welcome">
                <section className="container">
                    <h1 className="title has-white-text is-bold">
                        Welcome {contact}!
                    </h1>
                    <div className="welcome-text">
                        {props.hasCompleted ? (
                            <p className="has-gray-text is-light">
                                You have completed the journey. Click on the button below to view the results for your brand.
                            </p>
                        ) : (
                            <p className="has-gray-text is-light">
                                You have just onboarded on the journey to build your purpose driven brand. <br />
                                {"\n"}
                                Grab a cup of coffee, and some chocolate maybe, and buckle up. This exercise will take some time, <br />
                                so feel free to stop at any point, and come back to it anytime during this week. <br />
                                {"\n"}
                                By the end of this week, the questionnaire will end, we'll take your answers and begin crafting your <br />
                                brand assets and guidelines!
                            </p>
                        )}
                    </div>
                    <div className="welcome-cta">
                        <Link to={"/introduction"}>
                            <span>
                                Let's Go!
                                <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198">
                                    <g transform="translate(-489 -763)">
                                        <g transform="translate(489 763)" fill="none" stroke="#fce816" strokeWidth="5">
                                            <circle cx="99" cy="99" r="99" stroke="none" />
                                            <circle cx="99" cy="99" r="96.5" fill="none" />
                                        </g>
                                        <g transform="translate(-47.5 929.216) rotate(-90)">
                                            <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                            <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                            <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link>
                    </div>
                </section>
            </main>
            <main className="welcome__desktop ">
                <header className="welcome__desktop-header">
                    <a href="https://skinsagency.com">
                        <img src={images.logo} alt="logo" />
                    </a>
                </header>
                <div className="welcome__error">
                    <p className="welcome__error-text">
                        Uh Oh!
                    </p>
                </div>
                <h2 className="welcome__mobile-title">
                    This app does not work in mobile. Please use desktop.
                </h2>
                <p className="welcome__mobile-footer">
                    On your desktop, please visit <a href="https://app.skinsagency.com" className="has-yellow-text">app.skinsagency.com</a>
                </p>
                <div className="welcome__mobile register welcome-cta">
                    <a className="btn" href="https://brandpack.us/">
                        <span>
                            Go Back
                        </span>
                    </a>
                </div>
            </main>
        </>
    )
}