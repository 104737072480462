import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"
import { useRef } from "react"

export const StepTwo = (props) => {
    const elementRef = useRef(null);

    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }

    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                {/* <span className="formCount">1.1</span> */}
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/si86i74ne8k9ye80p3itt/12-The-Future-Benefit.mp4?rlkey=nr67rsg76oo74aou8ysxdrfo6&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="form__wrapper">
                    {!props.isFilled && (
                        <h3 className="form__title">Impact/Benefit</h3>
                    )}
                    {!props.isFilled ?
                        <p className="form__description" ref={elementRef}>
                            Do you expect to bring the same benefit moving forward? If no, add the new benefits to the list.
                        </p>
                        :
                        <></>
                    }
                    <fieldset className="flex-col">
                        <FieldArray name="impactBenefit2" {...props}>
                            {({ push }) => (
                                <div>
                                    {props.values.impactBenefit2.length > 0 &&
                                        props.values.impactBenefit2.map((proud, index) => (
                                            <Field
                                                name={`impactBenefit2.${index}.impactBenefit`}
                                                placeholder="New Impact or Benefit"
                                                className="has-border-bottom"
                                                disabled={props.isFilled}
                                                key={index}
                                                as={TextareaAutosize}
                                            // value={undefined}
                                            />
                                        ))}
                                    {!props.isFilled && (
                                        <AddMore onClick={() => push({ impactBenefit: '' })} />
                                    )}
                                </div>
                            )}
                        </FieldArray>
                    </fieldset>
                </div>
            </article>
        )
    }
}