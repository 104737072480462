import { TextareaAutosize } from "@mui/material"
import { Field, FieldArray } from "formik"
import ReactPlayer from "react-player"

export const StepTwo = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/kyks4cj5xjn1n5jts8ea9/6-What-are-you-passionate-about.mp4?rlkey=uec9q08kgahpk2e6n7iuza22c&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="form__wrapper">
                    {!props.isFilled && (
                        <h3 className="form__title">Clarifying your passion.</h3>
                    )}
                    {!props.isFilled ?
                        <p className="form__description">
                            Explain what emerged from those cases in one sentence, starting with: I am passionate about…
                        </p>
                        :
                        <></>
                    }
                    <fieldset className="flex-col">
                        <FieldArray name="emergedFromCases" {...props}>
                            <div>
                                {props.initialValues.emergedFromCases.length > 0 &&
                                    props.initialValues.emergedFromCases.map((passion, index) => (
                                        <Field
                                            name={`emergedFromCases.${index}.passion`}
                                            placeholder="I am passionate about..."
                                            className="has-border-bottom"
                                            disabled={props.isFilled}
                                            as={TextareaAutosize}
                                            key={index}
                                            // value={undefined}
                                        />
                                    ))}
                                {/* <AddMore onClick={handleClick(push)} /> */}
                            </div>
                        </FieldArray>
                    </fieldset>
                </div>
            </article>
        )
    }
}