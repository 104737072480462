import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer"
const downloadableDocumentStyles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#FCE816',
        height: '100%',
        width: '100%',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        fontSize: 60,
    },
    footer: {
        fontSize: 10,
        margin: 10,
        padding: 10,
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'flex-end'
    }
});

export const DownloadableDocument = (props) => {
    const StatementDocument = () => (
        <Document>
            <Page
                size="A4"
                style={downloadableDocumentStyles.page}
                orientation="landscape"
                width="1920"
            >
                <View style={downloadableDocumentStyles.section}>
                    <Text style={downloadableDocumentStyles.text}>
                        {props.statement}
                    </Text>
                </View>
                <View style={downloadableDocumentStyles.footer}>
                    <Text style={downloadableDocumentStyles.footer}>
                        Generated by BrandStarter
                    </Text>
                </View>
            </Page>
        </Document>
    )
    return (
        <PDFDownloadLink
            document={<StatementDocument />}
            fileName="purpose-statement.pdf"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198" className="statement__download">
                <g transform="translate(-1582 -763)">
                    <g transform="translate(1582 763)" fill="none" stroke="#000" strokeWidth="5">
                        <circle cx="99" cy="99" r="99" stroke="none" />
                        <circle cx="99" cy="99" r="96.5" fill="none" />
                    </g>
                    <g transform="translate(1045.5 929.216) rotate(-90)">
                        <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                        <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                        <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="5" />
                    </g>
                </g>
            </svg>
            <span>Download Purpose Statement</span>

        </PDFDownloadLink>
    )
}