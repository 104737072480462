import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { HeaderSidebar } from "../../../components/Header/HeaderSidebar";

export const PersonalityIntroduction = () => {
    return (
        <main className="personality has-black-bg container">
            <div className="video-container">
                <ReactPlayer
                    url="https://www.dropbox.com/scl/fi/spp6tonptltckp46kx2oz/19-Personality-and-Charisma.mp4?rlkey=r30z49ua6xlo0m3u0zzcd8tgw&raw=1"
                    playing
                    controls
                    className='react-player'
                    width="100%"
                    height="100%"
                />
                {/* <div className="video-container__text">
                    <h3 className="subtitle has-white-text">
                        The following section is timed. <br />
                        You will have <span className="has-yellow-text">5 minutes</span> to answer <br />the question.
                    </h3>
                </div> */}
            </div>
            <div className="stepper__buttons">
                <Link to={"/flow/vision/statement"}>
                    <span className="outlined-cta">
                        Back
                    </span>
                </Link>
                <Link to={"/flow/personality/values"}>
                    <span className="yellow-cta">
                        Next
                    </span>
                </Link>
            </div>
            <HeaderSidebar activeEl="Personality & Charisma" />
        </main>
    )
}