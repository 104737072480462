import { useNavigate } from "react-router-dom"
import { StatementHeader } from "../../../components/Header/HeaderStatement"
import { NextButtonBlack } from "../../../components/Buttons/NextButtonBlack"
import { useEffect, useState } from "react"
import { getCompletedPayment, makePayment } from "../../../services"
import { Field, Form, Formik } from "formik"
import { TextareaAutosize } from "@mui/material"
import { DownloadableDocument } from "../../../components/Downloadable/Document"
import { getPurposeStatement, submitPurposeStatement } from "../../../services/statementsService"

export const PurposeStatement = () => {
    const [hasPaid, setHasPaid] = useState(false);
    const [redirectLink, setRedirectLink] = useState("");
    const [initialValues, setInitialValues] = useState({
        purposeStatement: ''
    })
    const navigate = useNavigate();

    useEffect(() => {
        getCompletedPayment().then(response => {
            const data = response.data;
            if (data === 1) {
                setHasPaid(true)
            } else {
                setHasPaid(false);
            }
        }).catch(err => {
            console.log(err);
        });

        makePayment().then(response => {
            const data = response.data;
            if (data && !hasPaid) {
                setRedirectLink(data.url);
            }
        }).catch(err => {
            console.log(err);
        });

        getPurposeStatement().then(resp => {
            const data = resp.data;
            setInitialValues({ purposeStatement: data })
        }).catch(error => {
            console.log(error);
        });
    }, [hasPaid]);

    const handlePrevClick = () => {
        navigate("/flow/purpose/product?step=1")
    }

    const _handleSubmit = values => {
        submitPurposeStatement(values).then(response => {
            if (hasPaid) {
                navigate("/flow/vision");
            } else {
                window.location.href = redirectLink;
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <main className="purpose statement has-yellow-bg">
            <div className="container has-yellow-bg">
                <StatementHeader step="Purpose" />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={_handleSubmit}
                >
                    <Form>
                        <Field
                            name="purposeStatement"
                            placeholder="The Purpose Statement for your company is being generated. Feel free to edit it in case it doesn't match what you had in mind."
                            as={TextareaAutosize}
                        />
                        <p className="statement__paidText">
                            Please consider paying for the product. Close DevTools. <br />
                            Explicabo recusandae temporibus odio. Quos maxime veniam error <br />
                            ab ducimus exercitationem, dolorem soluta consequatur provident eum, <br />
                            omnis cum odit doloribus magni aliquam!
                        </p>
                        <footer className="statement__footer">
                            <DownloadableDocument statement={initialValues.purposeStatement} />
                            <div className="statement__footer-container">
                                <section>
                                    <button className="btn prev" onClick={handlePrevClick}>
                                        <NextButtonBlack />
                                    </button>
                                    <span>
                                        Product
                                    </span>
                                </section>
                                <section>
                                    <span>
                                        Vision
                                    </span>
                                    <button className="btn" type="submit">
                                        <NextButtonBlack />
                                    </button>
                                </section>
                            </div>
                        </footer>
                    </Form>
                </Formik>
            </div>
        </main>
    )
}