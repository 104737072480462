import { Field, FieldArray } from "formik"
import { AddMore } from "../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { TextareaAutosize } from "@mui/material"

export const StepOne = (props) => {
    const handleArticleClassName = () => {
        if(props.isFilled) return "form__container isFilled exit";
        if(props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/yhnd48kaa0s4j246gsbcz/17-The-Vision.mp4?rlkey=vl5fhsvsotxvwzskprbdye9wr&raw=1"
                        playing={!props.isFilled}
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <section className="form__question">
                    {!props.isFilled && (
                        <span className="formCount">1.</span>
                    )}
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">What will your company look like when <br />
                                it's reached its full potential?</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                When you think of what your company is going to look like when it has reached its full potential,
                                what will it look like? This could be a specific quantitative or qualitative goal of the next 5, 10 or 50 years.
                                The easiest way to do this is to start the sentence with: "To be…" and fill the rest of the sentence with the goal.
                            </p>
                            :
                            <></>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="companyPotential" {...props}>
                                {({ push }) => (
                                    <div>
                                        {props.values.companyPotential.length > 0 &&
                                            props.values.companyPotential.map((companyPotential, index) => (
                                                <Field
                                                    name={`companyPotential.${index}.companyPotential`}
                                                    placeholder="To be..."
                                                    className="has-border-bottom"
                                                    disabled={props.isFilled}
                                                    key={index}
                                                    as={TextareaAutosize}
                                                    // values={undefined}
                                                />
                                            ))}
                                        {!props.isFilled && (
                                            <AddMore onClick={() => push({ companyPotential: '' })} />
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}