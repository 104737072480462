import { useEffect, useState } from "react"
import { purpose } from "../../services/resultsService"
import { Step } from "./Step"

export const ResultsPurpose = () => {
    const [content, setContent] = useState({
        statement: 'Purpose Statement.',
        paragraph: 'Content is being loaded.'
    });

    const [companyName, setCompanyName] = useState('Your Company');

    const header = {
        index: "01",
        section: "Purpose",
        company: companyName
    }

    useEffect(() => {
        purpose().then(response => {
            const { purpose, company } = response.data;
            if (purpose) {
                setContent(JSON.parse(purpose));
            }
            company ? setCompanyName(company) : setCompanyName('Your Company');
        }).catch(error => {
            console.log(error);
        })
    }, [])

    return (
        <Step header={header}>
            <h1 className="results__title">
                {content.statement}
            </h1>
            <p className="results__description">
                {content.paragraph}
            </p>
        </Step>
    )
}