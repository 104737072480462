import { HeaderSidebar } from "../../../components/Header/HeaderSidebar";
import { IntroSteps } from "../../../steps/intro/IntroSteps";

export const Intro = () => {
    return (
        <div className="wrapper">
            <main className="intro has-black-bg container">
                <IntroSteps />
            </main>
            <HeaderSidebar activeEl="Intro" />
        </div>
    );
}