import { HeaderSidebar } from "../../../components/Header/HeaderSidebar"
import { ImpactSteps } from "../../../steps/purpose/impact/ImpactSteps"
export const PurposeImpact = () => {
    return (
        <div className="wrapper">
            <main className="impact has-black-bg container">
                <ImpactSteps />
            </main>
            <HeaderSidebar activeEl="Purpose" />
        </div>
    )
}