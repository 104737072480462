import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { completePayment, makePayment } from "../../services";

export const CheckoutForm = () => {
    const [name, setName] = useState("")

    const id = useSelector(state => {
        return state.user.user.id;
    })
    const searchParams = new URLSearchParams(document.location.search);
    const isSuccess = searchParams.get('success');
    useEffect(() => {
        completePayment(1).then(response => {
            const data = response.data;
            console.log(data);
        });
    }, []);
    return (
        <>
            <main className="has-black-bg welcome checkout">
                <section className="container">
                    <h1 className="title has-white-text is-bold">
                        Thank You for Your Purchase!
                    </h1>
                    <div className="welcome-text">
                        <div >
                            <p className="has-gray-text is-light">
                                Welcome to BrandStarter! <br />
                                {"\n"}
                                Your BrandStarter purchase is confirmed. We're excited to have you on board and can't wait to see your brand grow. <br />
                                {"\n"}

                                Click next to continue building your:
                            </p>
                            <ul>
                                <li className="has-gray-text is-light">Vision <br /></li>
                                <li className="has-gray-text is-light">Personality & Charisma</li>
                                <li className="has-gray-text is-light">Relationships</li>
                            </ul>

                            <p className="has-gray-text is-light">

                                Stay Connected:

                                Make sure to follow us across<a href="https://instagram.com/skins.agency" style={{ color: "#fce816" }} target="_blank"> social media </a>
                                and to watch our <a target="_blank" href="https://youtube.com/@skinsagency" style={{ color: "#fce816" }}>podcast</a> for more knowledge and information about branding.
                                {"\n"}
                                Thank you for choosing BrandStarter. Let's build something amazing together! <br />
                                {"\n"}
                                Best, <br />
                                The BrandStarter Team
                            </p>
                        </div>
                    </div>
                    <div className="welcome-cta">
                        <Link to={"/flow/vision"}>
                            <span>
                                To your vision!
                                <svg xmlns="http://www.w3.org/2000/svg" width="198" height="198" viewBox="0 0 198 198">
                                    <g transform="translate(-489 -763)">
                                        <g transform="translate(489 763)" fill="none" stroke="#fce816" strokeWidth="5">
                                            <circle cx="99" cy="99" r="99" stroke="none" />
                                            <circle cx="99" cy="99" r="96.5" fill="none" />
                                        </g>
                                        <g transform="translate(-47.5 929.216) rotate(-90)">
                                            <line y2="73.093" transform="translate(66.858 597.5)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                            <line x1="39.358" y2="39.358" transform="translate(66.858 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                            <line x2="39.358" y2="39.358" transform="translate(27.5 634.984)" fill="none" stroke="#fce816" strokeLinecap="round" strokeWidth="5" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </Link>
                    </div>
                </section>
            </main>
        </>
    )
}