export const HeaderResults = (props) => {
    return (
        <header className="results__header">
            <div className="header__step">
                <span className="header__number active">
                    {props.index}
                </span>
                <div className="header__divider active"></div>
                <div className="secondary__wrapper">
                    <span className="header__name">
                        {props.name}
                    </span>
                    <span className="header__section">
                        {props.sectionName}
                    </span>
                </div>
            </div>
        </header>
    )
}