import { TextareaAutosize } from "@mui/material"
import { Field, FieldArray } from "formik"
import ReactPlayer from "react-player"

export const StepThree = (props) => {
    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                <div className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}>
                    <ReactPlayer
                        url="https://www.dropbox.com/scl/fi/0ums4fh3vkd8ihbpgpxzm/10-The-Persona.mp4?rlkey=mw1e84n6avebjg75eyx81vjex&raw=1"
                        playing
                        controls
                        className='react-player'
                        width="100%"
                        height="100%"
                    />
                </div>
                <div className="form__wrapper">
                    {!props.isFilled && (
                        <h3 className="form__title">Combine</h3>
                    )}
                    {!props.isFilled ?
                        <p className="form__description">
                            How does the personality of the people you have helped
                            before connect to the people you are targeting now? Combine
                            attributes of two groups into one phrase to build one Ideal
                            Customer Profile.
                        </p>
                        :
                        <></>
                    }
                    <fieldset className="flex-col">
                        <FieldArray name="combine" {...props}>
                            <div>
                                {props.initialValues.combine.length > 0 &&
                                    props.initialValues.combine.map((combine, index) => (
                                        <Field
                                            name={`combine.${index}.combine`}
                                            placeholder="Adjective + Name"
                                            className="has-border-bottom"
                                            key={index}
                                            as={TextareaAutosize}
                                            // value={undefined}
                                        />
                                    ))}
                                {/* <AddMore onClick={handleClick(push)} /> */}
                            </div>
                        </FieldArray>
                    </fieldset>
                </div>
            </article>
        )
    }
}