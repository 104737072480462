import { Field, FieldArray } from "formik"
import { AddMore } from "../../../components/Buttons/AddMoreButton"
import ReactPlayer from "react-player"
import { useState } from "react"
import Countdown from "react-countdown";
import { useRef } from "react";

export const StepOne = (props) => {
    const timerRef = useRef(null);
    const [dateNow, setDateNow] = useState(Date.now());
    const [isDisabled, setIsDisabled] = useState(false);
    const [showTimeOut, setShowTimeout] = useState(false);


    const countdownRenderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setIsDisabled(true);
            setShowTimeout(true);
        } else {
            if (seconds < 10) {
                return (
                    <span className="has-yellow-text">
                        0{minutes} : 0{seconds}
                    </span>
                );
            } else {
                return (
                    <span className="has-yellow-text">
                        0{minutes} : {seconds}
                    </span>
                );
            }
        }
    };

    const handleArticleClassName = () => {
        if (props.isFilled) return "form__container isFilled exit";
        if (props.enteringStep) return "form__container enter";
        else return "form__container";
    }
    if (props.isActive) {
        return (
            <article className={handleArticleClassName()}>
                {!props.secondRowIsVisible && (
                    <div
                        className={
                            props.isFilled
                                ? "flex centerAligned gap-1 hidden timerContainer"
                                : "flex centerAligned gap-1 timerContainer"
                        }>
                        {!props.isFilled && (
                            !showTimeOut ? (
                                <Countdown
                                    ref={timerRef}
                                    date={dateNow + 5 * 60 * 1000}
                                    renderer={countdownRenderer}
                                />
                            ) : (
                                <span className="has-red-text">
                                    Timeout!
                                </span>
                            )
                        )}
                        <div
                            className={props.isFilled && props.isActive ? "purpose__video hidden" : "purpose__video visible"}
                        >
                            <ReactPlayer
                                url="https://www.dropbox.com/scl/fi/pfakteodiwp6cqqt9t2xm/24-Tone-of-Voice.mp4?rlkey=pxh1td35cp6i1ux4dp1mrt04w&raw=1"
                                playing={!props.isFilled}
                                controls
                                className='react-player'
                                width="100%"
                                height="100%"
                            />
                        </div>
                    </div>
                )}
                <section className="form__question">
                    {!props.isFilled && (
                        <span className="formCount">3.</span>
                    )}
                    <div className="form__wrapper">
                        {!props.isFilled && (
                            <h3 className="form__title">Tone of Voice</h3>
                        )}
                        {!props.isFilled ?
                            <p className="form__description">
                                Now think what that person would sound like if they
                                approached you, shook your hand, and started a conversation?
                                If it was a bar, how would you describe the music that is playing?
                                - Only use adjectives that describe a tone of voice, and not visuals.
                            </p>
                            :
                            <p className="form__description">{"\n"}</p>
                        }
                        <fieldset className="flex-col">
                            <FieldArray name="allToneAttributes" {...props}>
                                {({ push }) => (
                                    <div>
                                        {props.values.allToneAttributes.length > 0 &&
                                            props.values.allToneAttributes.map((toneAttribute, index) => (
                                                <Field
                                                    name={`allToneAttributes.${index}.toneAttribute`}
                                                    placeholder="Adjective"
                                                    className="has-border-bottom"
                                                    disabled={isDisabled || props.isFilled}
                                                    key={index}
                                                    // value={undefined}
                                                />
                                            ))}
                                        {!props.isFilled && (
                                            <AddMore onClick={() => push({ toneAttribute: '' })} />
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </fieldset>
                    </div>
                </section>
            </article>
        )
    }
}