import { axiosInstance as axios } from "./axiosInstance";

const URL = `/api/v2/forms`

export const getIntroForm = () => {
    return axios.get(`${URL}/intro`);
}

export const getPassionForm = () => {
    return axios.get(`${URL}/passion`);
}

export const getPeopleForm = () => {
    return axios.get(`${URL}/people`);
}

export const getImpactForm = () => {
    return axios.get(`${URL}/impact`);
}

export const getProductForm = () => {
    return axios.get(`${URL}/product`);
}

export const getVisionForm = () => {
    return axios.get(`${URL}/vision`);
}

export const getValuesForm = () => {
    return axios.get(`${URL}/values`);
}

export const getLookForm = () => {
    return axios.get(`${URL}/look`);
}

export const getToneForm = () => {
    return axios.get(`${URL}/tone`);
}

export const getAttributesForm = () => {
    return axios.get(`${URL}/attributes`);
}

export const getCompetitionForm = () => {
    return axios.get(`${URL}/competition`);
}

export const getTrendsForm = () => {
    return axios.get(`${URL}/trends`);
}