import styled from "@emotion/styled"
import { Dialog } from "@mui/material"

export const FinishDialog = ({ ...props }) => {
    const CustomDialog = styled(Dialog)`
    .MuiPaper-root {
        background-color: #FCE816;
        padding: 2em;
    }
    `

    return (
        <CustomDialog {...props}>
            <div className="dialog__wrapper finish">
                {props.children}
            </div>
        </CustomDialog>
    )
}


