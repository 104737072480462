import { useEffect, useState } from "react";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { submitIntroForm } from "../../services";
import { useSelector } from "react-redux";
import { getIntroForm } from "../../services/autofillService";
import { Box } from "../../components/Progress/Box";
import { StepperButtons } from "../../components/Stepper/Buttons";

const steps = ['Company Name', 'Problem Statement', 'Problem Solution'];

function _renderStepContent(step, leavingStep, errors, touched) {
    switch (step) {
        case 0:
            return <StepOne name="companyName" triggeredAnimation leavingStep errors={errors} touched={touched} />
        case 1:
            return <StepTwo name="problemStatement" isActive triggeredAnimation errors={errors} touched={touched} />
        case 2:
            return <StepThree name="problemSolution" isActive triggeredAnimation errors={errors} touched={touched} />
        default:
            return <div>Not Found</div>
    }
}

export const IntroSteps = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [initialValues, setInitialValues] = useState({
        companyName: '',
        problemStatement: '',
        problemSolution: '',
    })

    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [leavingStep, setLeavingStep] = useState(0);

    /* @TODO: Either do this in a better way or remove altogether */

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const isLastStep = activeStep === steps.length - 1;

    const _submitForm = (values, actions) => {
        setIsLoading(true);
        submitIntroForm(id, JSON.stringify(values)).then(response => {
            setIsLoading(false);
            navigate("/flow/purpose");
        }).catch(err => {
            setIsLoading(false);
            console.log(err)
        })
    }

    const _handleSubmit = (values, actions) => {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            navigate(`?step=${activeStep + 1}`)
            setLeavingStep(activeStep);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const _handleBack = () => {
        setActiveStep(activeStep - 1);
        navigate(`?step=${activeStep - 1}`)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParams = searchParams.get('step');
        const parsedStep = parseInt(stepParams, 10);

        if (!isNaN(parsedStep) && parsedStep > 0) {
            setActiveStep(parsedStep);
        }

        getIntroForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data)
            }
        }).catch(err => {
            console.log(err);
            setInitialValues({
                companyName: '',
                problemStatement: '',
                problemSolution: ''
            })
        })
    }, [id, activeStep, location.search]);

    return (
        <section className={"formsWrapper"}>
            {activeStep === steps.length ? (
                <>Something has gone wrong. Please refresh!</>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                >
                    {({ values, errors, touched }) => (
                        <Form>
                            <Box step={activeStep} steps={steps.length} />
                            {_renderStepContent(activeStep, leavingStep, errors, touched, values)}
                            <StepperButtons
                                activeStep={activeStep}
                                link="/introduction"
                                handleBack={_handleBack}
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </section>
    )
}