import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'User',
    initialState: {
        user: {
            id: null,
            name: null,
            token: null,
            authenticated: false,
            completed: null,
        }
    },
    reducers: {
        loginReducer(state, action) {
            const token = sessionStorage.getItem('token');
            if (token !== '') {
                sessionStorage.setItem('token', '');
            }
            sessionStorage.setItem('token', action.payload.token);
            
            state.user['id'] = action.payload.id;
            state.user['name'] = action.payload.name;
            state.user['token'] = action.payload.token;
            state.user['authenticated'] = true;
            state.user['completed'] = action.payload.completed;
        },
        logoutReducer(state) {
            state.user['id'] = null;
            state.user['name'] = null;
            state.user['token'] = null;
            state.user['authenticated'] = false;
            state.user['completed'] = null;
        },
    }
});

export const { loginReducer, logoutReducer } = userSlice.actions;

export default userSlice.reducer;