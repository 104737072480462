import { Form, Formik } from "formik";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { StepOne } from "./StepOne.jsx";
import { StepTwo } from "./StepTwo";
import { submitProductForm } from "../../../services/index.js";
import { getProductForm } from "../../../services/autofillService.js";
import { Box } from "../../../components/Progress/Box.jsx";
import { StepperButtons } from "../../../components/Stepper/Buttons.jsx";

const steps = ['Product/Service', 'Product/Service'];

export const ProductSteps = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [initialValues, setInitialValues] = useState({
        productService1: [
            {
                productService: ''
            },
            {
                productService: ''
            },
            {
                productService: ''
            },
            {
                productService: ''
            },
        ],
        productService2: [
            {
                productService: '',
            },
        ],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [enteringStep, setEnteringStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;

    const id = useSelector(state => {
        return state.user.user.id;
    });

    const _renderStepContent = (step, values, enteringStep) => {
        switch (step) {
            case 0:
                return (
                    <div className="flex gap-1 startAligned">
                        <StepOne
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 0 ? "enter" : ""}
                        />
                        <StepTwo initialValues={initialValues} isActive={false} values={values} />
                    </div>
                )
            case 1:
                return (
                    <div className="flex gap-1 startAligned">
                        <StepOne initialValues={initialValues} isActive isFilled values={values} />
                        <StepTwo
                            initialValues={initialValues}
                            isActive
                            values={values}
                            enteringStep={enteringStep === 1 ? "enter" : ""}
                        />
                    </div>
                )
            default:
                return <div>Something went wrong</div>
        }
    }

    const _submitForm = values => {
        setIsLoading(true);
        submitProductForm(id, values).then(response => {
            setIsLoading(false);
            navigate("/flow/purpose/statement")
        }).catch(err => {
            setIsLoading(false);
            console.log(err);
        })
    }

    const _handleSubmit = values => {
        if (isLastStep) {
            _submitForm(values);
        } else {
            setActiveStep(activeStep + 1);
            navigate(`?step=${activeStep + 1}`)
            setEnteringStep(activeStep + 1);
        }
    }

    const _handleBack = () => {
        setActiveStep(activeStep - 1);
        navigate(`?step=${activeStep - 1}`)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const stepParams = searchParams.get('step');
        const parsedStep = parseInt(stepParams, 10);

        if (!isNaN(parsedStep) && parsedStep > 0) {
            setActiveStep(parsedStep);
        }
        getProductForm().then(response => {
            const data = response.data;
            if (data) {
                setInitialValues(data);
            }
        }).catch(err => {
            console.error(err);
        })
    }, [activeStep, location.search]);

    return (
        <section className="product formsWrapper">
            {activeStep === steps.length ? (
                <>You have finished this section</>
            ) : (
                <Formik
                    initialValues={initialValues}
                    onSubmit={_handleSubmit}
                    enableReinitialize
                >
                    {({ values }) => (
                        <Form>
                            <Box step={activeStep} steps={steps.length} />
                            {_renderStepContent(activeStep, values, enteringStep)}
                            <StepperButtons
                                activeStep={activeStep}
                                handleBack={_handleBack}
                                link="/flow/purpose/impact?step=2"
                                isLoading={isLoading}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </section>
    )
}