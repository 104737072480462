import { Link, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/file";

export const Introduction = ({completed}) => {
    const navigate = useNavigate();
    if(completed) {
        navigate("/results")
    }
    return (
        <main className="introduction has-black-bg container">
            <h2 className="has-white-text">
                Introduction
            </h2>
            <div className="video-container">
                <ReactPlayer
                    url="https://www.dropbox.com/scl/fi/p9xoclckxbcn32wa6ygw5/1-Welcome.mp4?rlkey=gtccladqszb1n6gdi89t1qphp&raw=1"
                    playing
                    controls
                    className='react-player'
                    width="100%"
                    height="100%"
                />
            </div>
            <Link to="/flow/intro?step=0" className="yellow-cta">
                Next
            </Link>
        </main>
    )
}